<template>
<section class="sams-pay-uclan">
    <router-view></router-view>
</section>
</template>


<script src="https://unpkg.com/boxicons@2.1.2/dist/boxicons.js"></script>
<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import Routes from './SamsStudentPortal/routes.js';
import $ from 'jquery';
import vClickOutside from 'v-click-outside';

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

//import AppJs from '../src/custom/vuexy/app.js';
//import AppMenuJs from '../src/custom/vuexy/app-menu.js';
import feather from 'feather-icons';

$('body').addClass('pace-done horizontal-layout horizontal-menu navbar-floating footer-static');
$('body').attr('data-open', 'hover');
$('body').attr('data-menu', 'horizontal-menu');

//AppJs(window, document, $);
//AppMenuJs(window, document, $);
// We listen to the resize event

window.addEventListener('resize', function () {
  // We execute the same script as before
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
});

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(vClickOutside);

const store=new Vuex.Store({
  state:{
    base_url:'',
    api_url:'',
    api_token:'',
    //api_key:'',
    site:{
      auth_user:{
        name: 'Unknown',
        user_permissions: [],
        permissions: [],
        steps_submitted_at: false
      },
      university:{
        logo_url:'#',
        commission_type_id: ''
      },
      countries:[],
      permission_disable: false
    }
  },
  mutations: {
    setBaseUrl: function(state, value){
      state.base_url=value;
    },
    setApiUrl: function(state, value){
      state.api_url=value;
    },
    setApiToken: function(state, value){
      state.api_token=value;
    },
    changeStudentStageStatus: function(state, value){
      state.site.auth_user.steps_submitted_at=value;
    },
    /*setApiKey: function(state, value){
      state.api_key=value;
    },*/
    siteInfo: function(state, args){
      state.site[args.key]=args.val;
    }
  }
});

const router=new VueRouter({
  routes: Routes,
});

router.beforeEach((to, from, next)=>{

  var api_token=localStorage.getItem('student_api_token');

  if(to.name == 'ForgotPassword' || to.name=='') next();
  else if(to.name == 'GdprAgreementConfirmation') next();
  else if(to.name == 'ResetPassword') next();

  else if(to.name!=='Login' && !api_token) next({ name: 'Login' });
  else if(to.name=='Login' && api_token) next('/dashboard');
  else next();
  
});

export default {
  store,
	router,
	name: 'SamsStudentPortal',
	props: ['api_url', 'base_url', 'api_token'/*, 'api_key'*/],
  metaInfo: {
    // title: 'Student Portal',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Sams Crm student portal.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  data() {
    return {

    }
  },
	created: async function(){

    var ref=this;
    var jq=ref.jq();

		ref.$store.commit('setBaseUrl', ref.base_url);
		ref.$store.commit('setApiUrl', ref.api_url);

    var api_token=localStorage.getItem('student_api_token');

    if(api_token){

      ref.$store.commit('setApiToken', api_token);
      ref.ajax_setup('student_api_token');

      try{

        //ref.check_uni_auth();

        var res=await jq.get(ref.url('api/v2/student/ajax/auth_user_info'));

        // if(res.data.auth_user){
        //   localStorage.removeItem('student_api_token');
        //   ref.$router.push('/login');
        // }

        ref.$store.commit('siteInfo', {
          key: 'auth_user',
          val: res.data.auth_user
        });

        ref.$store.commit('siteInfo', {
          key: 'countries',
          val: res.data.countries
        });
        

        // this.authUser = res.data.auth_user

        document.title = this.cn(this.$store.state.site.auth_user, 'name', 'User Name') + ' | Dashboard'

        // this.metaInfo.title = res.data.auth_user.name

        // ref.$store.commit('siteInfo', {
        //   key: 'university',
        //   val: res.data.auth_user.university
        // });

        // ref.$store.commit('siteInfo', {
        //   key: 'countries',
        //   val: res.data.countries
        // });

      }catch(err){
        //ref.alert(ref.err_msg(err), 'error');
        localStorage.removeItem('student_api_token');
        ref.$router.push('/login');
      }

    }

	},
  mounted: function(){
    
    feather.replace();

  }
}

</script>

<style>
@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,400;1,500;1,600";
@import '../static/vuexy/app-assets/vendors/css/vendors.min.css';
@import '../static/vuexy/app-assets/css/bootstrap.css';
@import '../static/vuexy/app-assets/css/bootstrap-extended.css';
@import "../static/vuexy/app-assets/css/colors.css";
@import "../static/vuexy/app-assets/css/components.css";
@import "../static/vuexy/app-assets/css/themes/dark-layout.css";
@import "../static/vuexy/app-assets/css/themes/bordered-layout.css";
@import "../static/vuexy/app-assets/css/themes/semi-dark-layout.css";
@import "../static/vuexy/assets/css/style.css";
@import "../static/vuexy/app-assets/css/plugins/forms/form-wizard.css";
@import "../static/vuexy/app-assets/vendors/css/forms/wizard/bs-stepper.min.css";
@import "../static/vuexy/app-assets/css/core/menu/menu-types/horizontal-menu.css";
@import "../static/vuexy/app-assets/vendors/css/charts/apexcharts.css";
@import "../static/vuexy/app-assets/css/plugins/charts/chart-apex.css";

@import "../static/waitMe/waitMe.min.css";

@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
@import "https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css";
@import "~flatpickr/dist/flatpickr.min.css";
@import "~noty/lib/noty.css";
@import "~noty/lib/themes/sunset.css";
@import "~bootstrap-sweetalert/dist/sweetalert.css";
@import "~@riophae/vue-treeselect/dist/vue-treeselect.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";


/*@import "../static/added/lead_profile.css";
@import "../static/added/custom_style.css";
@import "../static/added/full_profile.css";*/
@import "../static/added/editor.min.css";
@import "./pretty-checkbox.min.css";

.hand{
   cursor: pointer;
}

.swal2-cancel {
  margin-right: 10px;
}
.form-control:focus {
    color: #6e6b7b;
    background-color: #fff;
    border-color: rgb(4, 126, 175) !important;
    outline: 0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li.active > a {
    background: rgb(4, 126, 175) !important;
    box-shadow: unset !important;
    color: #fff !important;
    border-radius: 4px !important;
}
.page-item.active .page-link {
    background-color: rgb(4, 126, 175) !important;
}
.horizontal-menu .header-navbar.navbar-horizontal .nav-link.dropdown-toggle::after {
  left: 0.4rem;
 
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
}

.horizontal-menu .header-navbar.navbar-horizontal .sidebar-group-active .dropdown-toggle::after {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")!important;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 4px;
  padding: 5px 10px 4px;
  opacity: .7;
  font-family: 'Proxima Nova', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
.text-uclan {
    color: rgb(4, 126, 175) !important;
    font-weight: 500;
}
.text-uclan:hover {
    color: rgb(13, 210, 245) !important;
    font-weight: 800;
}
.text-uclan-b {
    color: rgb(4, 126, 175) !important;
    font-weight: 800;
}
.text-uclan-b5 {
    color: rgb(4, 126, 175) !important;
    font-weight: 500;
}
.text-primary{
    color: rgb(4, 126, 175) !important;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
    width: 14rem !important;
    /* margin-top: 10px; */
}
.breadcrumb-item a {
    color: rgb(4, 126, 175) !important;
}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
    color: rgb(4, 126, 175) !important;
}
.bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
    background-color: rgb(4, 126, 175) !important;
}
.badge-primary {
    background-color: rgb(4, 126, 175) !important;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: rgb(4, 126, 175) !important;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a:hover {
    color: #ffa8a8 !important;
    /* color: purple !important; */
}
.badge.badge-light-blue {
    background-color: rgba(0, 4, 255, 0.103);
    color: #002fff !important;
}

.badge-light-blue {
    background-color: rgba(0, 4, 255, 0.103);
}
.badge-purple {
    background-color: #7367F0;
}
.bg-purple {
    background-color: #7367F0;
}
.bg-light-purple {
    background-color: #eceaff;
}
.avatar.bg-light-blue {
    background-color: rgba(0, 4, 255, 0.103);
    color: #002fff !important;
}

.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-50 {
  border-radius: 50px;
}
.border-radius-75 {
  border-radius: 75px;
}
.box-shadow {
  box-shadow: 0px 1px 5px 0px #6a6a6a9c;
}
.box-shadow-inset {
  box-shadow: inset 0 0 5px #dadada, 0 0 5px white;
}
.border-radius-100 {
  border-radius: 100px;
}
.font-size-10{
  font-size: 10px !important;
}
.font-size-11{
  font-size: 11px !important;
}
.font-size-12{
  font-size: 12px !important;
}
.font-size-13{
  font-size: 13px !important;
}
.font-size-14{
  font-size: 14px !important;
}
.font-size-15{
  font-size: 15px !important;
}
.font-size-16{
  font-size: 16px !important;
}
.font-size-18{
  font-size: 18px !important;
}
.font-size-20{
  font-size: 20px !important;
}
.font-size-22{
  font-size: 22px !important;
}
.font-size-24{
  font-size: 24px !important;
}
.font-size-26{
  font-size: 26px !important;
}
.font-size-28{
  font-size: 28px !important;
}
.font-size-30{
  font-size: 30px !important;
}

.bg-light-orange {
    /* background: rgb(255 253 241) !important; */
    background: rgb(252 234 234) !important;
}

.bg-light-gray {
  background: rgb(212 212 212) !important;
}

.bg-lighter-red {
  background: #ffeded !important;
}

.bg-light-green {
  background: #B3FFB3 !important;
  /* color: #018101 !important; */
}

.bg-light-purple {
  background: #DABCF5 !important;
  /* color: #8F5EE3 !important; */
}

.bg-light-blue {
  background: #B3B3FF !important;
  /* color: #343CFF !important; */
}

.box-shadow {
  box-shadow: 0px 1px 3px #c2c2c2 !important
}
.zoom {
  transition: 400ms;
}
.zoom:hover {
  transform: scale(1.5);
}
.card {
    box-shadow: none !important;
}
.btn {
      border-radius: 50px !important;
}

/*.modal .modal-header {
    background-color: #2e1153 !important;
}*/
.modal-title {
    color: #FFF !important;
}
.modal-title > span {
    color: #FFF !important;
}
.guideline_div {
    /* border: 2px dashed rgb(115 103 240) !important; */
    border: 2px dashed rgb(4, 126, 175) !important;
    /* width: 100% !important;
    height: 100% !important; */
}

.text_by_other {
    background: #e4e6eb;
    color: #000;
    margin: 0px;
    padding: 10px 10px 10px 10px;
    border-radius: 20px 15px 15px 0px;
}
.text_by_me {
    background: rgb(4, 126, 175);
    color: #FFF;
    padding: 10px 10px 10px 10px;
    border-radius: 15px 20px 0px 15px;
}
.text_by_me a{
    color: rgb(200, 255, 0);
}
.text_by_me a:hover{
    color: rgba(200, 255, 0, 0.651);
}
.comment_block_white{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}
.comment_block_gray{
  padding: 5px;
  background-color: #e4e6eb;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}
.border-right-light-gray{
  border-right: 2px solid #b8b8b8;
}
.submit_button {
    background-color: #2e1153 !important;
    border-width: 1px !important;
    border-color: #2e1153 !important;
    color: #fff !important;
    border-radius: 30px !important;
    padding: 0 15px !important;
    height: 28px !important;
    font-size: 1.2rem !important;
    line-height: 1 !important;
    font-weight: 600 !important;
}
.submit_button:hover {
    background-color: #fff !important;
    border-color: #2e1153 !important;
    color: #2e1153 !important;
}
.color-navy-blue {
  color: #2E1153 !important;
}
.action_button {
  padding: 4px 6px;
  font-size: 12px;
  border-radius: 50%;
  background: #dedbf1;
  color: #826aa0;
  cursor: pointer;
  border: 1px solid transparent;
}
.action_button:hover {
    color: #ff7f00 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #ff7f00;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Regular.eot);
    src: url(../static/fonts/Proxima_Nova_Regular.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Regular.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Regular.woff) format('woff'), url(../static/fonts/Proxima_Nova_Regular.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Regular.svg#Proxima_Nova_Regular) format('svg');
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Regular_Italic.eot);
    src: url(../static/fonts/Proxima_Nova_Regular_Italic.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Regular_Italic.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Regular_Italic.woff) format('woff'), url(../static/fonts/Proxima_Nova_Regular_Italic.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Regular_Italic.svg#Proxima_Nova_Regular_Italic) format('svg');
    font-style: italic;
    font-weight: 400
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Bold.eot);
    src: url(../static/fonts/Proxima_Nova_Bold.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Bold.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Bold.woff) format('woff'), url(../static/fonts/Proxima_Nova_Bold.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Bold.svg#Proxima_Nova_Bold) format('svg');
    font-style: normal;
    font-weight: 700
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Bold_Italic.eot);
    src: url(../static/fonts/Proxima_Nova_Bold_Italic.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Bold_Italic.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Bold_Italic.woff) format('woff'), url(../static/fonts/Proxima_Nova_Bold_Italic.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Bold_Italic.svg#Proxima_Nova_Bold_Italic) format('svg');
    font-style: italic;
    font-weight: 700
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Semibold.eot);
    src: url(../static/fonts/Proxima_Nova_Semibold.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Semibold.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Semibold.woff) format('woff'), url(../static/fonts/Proxima_Nova_Semibold.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Semibold.svg#Proxima_Nova_Semibold) format('svg');
    font-style: normal;
    font-weight: 600
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Semibold_Italic.eot);
    src: url(../static/fonts/Proxima_Nova_Semibold_Italic.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Semibold_Italic.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Semibold_Italic.woff) format('woff'), url(../static/fonts/Proxima_Nova_Semibold_Italic.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Semibold_Italic.svg#Proxima_Nova_Semibold_Italic) format('svg');
    font-style: italic;
    font-weight: 600
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Light.eot);
    src: url(../static/fonts/Proxima_Nova_Light.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Light.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Light.woff) format('woff'), url(../static/fonts/Proxima_Nova_Light.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Light.svg#Proxima_Nova_Light) format('svg');
    font-style: normal;
    font-weight: 300
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(../static/fonts/Proxima_Nova_Light_Italic.eot);
    src: url(../static/fonts/Proxima_Nova_Light_Italic.eot) format('embedded-opentype'), url(../static/fonts/Proxima_Nova_Light_Italic.woff2) format('woff2'), url(../static/fonts/Proxima_Nova_Light_Italic.woff) format('woff'), url(../static/fonts/Proxima_Nova_Light_Italic.ttf) format('truetype'), url(../static/fonts/Proxima_Nova_Light_Italic.svg#Proxima_Nova_Light_Italic) format('svg');
    font-style: italic;
    font-weight: 300
}
body, html, input, select, textarea{
  font-family: 'Proxima Nova', sans-serif !important;
  font-size: 12px !important;
  color: #343434 !important;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-family: 'Proxima Nova', sans-serif !important;
}
.badge-light-green {
  background: rgb(231, 243, 234) !important; 
  color: rgb(11, 165, 46) !important; 
  padding: 2px 10px;
}
.badge-lighter-green {
  background: #d8fdd8 !important; 
  color: green !important;
}
.badge-lighter-red {
  background: #f3dadc !important; 
  color: #de1528 !important;
}

/*.custom_drop{
      display: inline;
  }*/
  .custom_drop .fa{
      padding: 4px 9px 3px;
      font-size: 1rem;
      border: 1px solid transparent;
  }
  .custom_drop .fa:hover {
      color: #ff7f00 !important;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s !important;
      border: 1px solid #ff7f00 !important;
  }
  .custom_drop .dropdown-toggle:after{
      display: none;
  }
  .custom_drop .dropdown-menu.show{
      margin-left: -30px;
      min-width: auto;
      padding: 0;
      margin-top: -6px;
      text-align-last: left;
  }
  .custom_drop .dropdown-menu.show .dropdown-item{
      background: #dedbf1;
      color: #2e1153;
      font-size: 12px;
      border: none;
      padding: 4px 12px;
      border-radius: 0;
  }
  .custom_drop .dropdown-menu.show .dropdown-item:hover{
      background: #2e1153;
      color: #fff !important;
  }
  .custom_drop .dropdown-menu.show .activate{
      background: #2e1153;
      color: #fff;
  }
  .custom_drop .dropdown-menu.show .dropdown-item:first-child{
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
  }
  .custom_drop .dropdown-menu.show .dropdown-item:last-child{
      border-bottom-left-radius: .25rem;
      border-bottom-right-radius: .25rem;
  }
  .custom_drop .fa {
      color: #826aa0;
  }

  
.vue-dropdown {}
.vue-dropdown ul {
    background: #dedbf1;
    padding: 0;
    top: -11px !important;
    left: -38px !important;
    min-width: auto;
    border: 1px solidrgba(0,0,0,.15);
    border-radius: 4px;
}

.vue-dropdown ul li {
  border-radius: 4px 4px 0px 0px;
}
.vue-dropdown ul li a.dropdown-item {
    background: #dedbf1;
    color: #2e1153;
    font-size: 12px;
    border: none;
    padding: 4px 8px;
}
.vue-dropdown ul li a.dropdown-item:hover {
    background: #2e1153;
    color: #fff !important;
}
.vue-dropdown ul li a.dropdown-item:first-child{
    border-radius: 4px 4px 0px 0px;
}
.vue-dropdown ul li a.dropdown-item:last-child {
    /* border-radius: 0px 0px 4px 4px; */
}

.modal-header{
    background: #17092a !important;
    color: #fff;
    padding: 15px !important;
}
.modal-content{
    border-color: transparent;
}
.modal-body{
    background: #f5f4fd !important;
    padding: 15px !important;
}
.modal-header .font-icon-close-2{
    background: #dedbf1;
    padding: 5px 5px 3px;
    font-size: 11.2px;
    border-radius: 50%;
    color: #826aa0;
    border: 1px solid transparent;
}
.modal-header .modal_title{
    font-size: 16px;
    letter-spacing: .2px;
    font-weight: 600;
    line-height: 1.5;
    color: #fff;
}
.modal-header .font-icon-close-2:hover {
    color: #ff7f00 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #ff7f00;
}
.modal-header button{
  background: transparent;
  padding: 0;
  border: none;
  margin-top: 5px;
}



@media only screen and (max-width: 600px) {
  .header-navbar .fa-bars{
    display: none;
  }
  .header-navbar .navbar-container .align-items-center .header_left .nav-item{
    display: block !important;
  }
  .header-navbar .navbar-container .align-items-center .header_left .nav-item div span:first-child{
    line-height: 1;
  }
  .header-navbar .navbar-container .align-items-center .header_left .nav-item img{
    margin-bottom: 10px;
  }
  .header-navbar .navbar-container{
    display: block !important;
    min-height: 175px;
  }
  .header-navbar .navbar-container .header_right .dropdown-user .dropdown-user-link .user-nav{
    display: block !important;
  }
  .header-navbar .navbar-container .header_right{
    margin-top: 10px;
  }
  .top_part_responsive{
    margin-top: 195px !important;
  }
  .top_part_responsive .whole_part{
    display: inline-block;
    height: auto;
  }
  .top_part_responsive .whole_part .right_part .right_part_right_whole{
    /*display: inline-block;*/

    display: -webkit-box;
    overflow-x: scroll;
  }
  .top_part_responsive .whole_part .right_part .right_part_right_whole .summary_bordered_box{
    min-height: 130px;
    height: auto !important;
    margin-top: 15px !important;

    width: 250px !important;
  }
  .top_part_responsive .whole_part .right_part .right_part_right{
    margin-top: 140px;
  }
  .top_part_responsive .whole_part .right_part{
    width: 100%;
  }
  .tab_content_components .ad_pref_part .create_admission_preference{
    overflow-x: scroll;
  }
  .tab_content_components .ad_pref_part .create_admission_preference .ad_pref_item_header, .tab_content_components .ad_pref_part .create_admission_preference .scrolled_content_part{
    width: fit-content;
  }
  .tab_content_components .enrollments .enrollment_right_responsive{
    padding-right: 0;
    margin: 15px 0;
  }
  .tab_content_components .enrollments .enrollment_prog_responsive{
    overflow-x: scroll;
  }
  .tab_content_components .enrollments .enrollment_prog_responsive .box-typical-header{
    width: max-content;
  }
  .student-full-profile-stages #full_profile_stage_part #stages{
    height: auto;
  }
  .vue-image-crop-upload .vicp-wrap{
    width: fit-content;
  }
  .tab_content_components #crm_profile_div .list_table{
    overflow-x: scroll;
    width: max-content;
    min-width: -webkit-fill-available;
  }
  .documentation_part .single_doc_item{
    width: 600px;
  }
  .modal.show{
    padding: 0 12px !important;
  }
  .overflow_div_responsive{
    overflow-x: scroll;
  }
}


@media (min-width: 600px) and (max-width: 768px) {
  .header-navbar .fa-bars{
    display: none;
  }
  .header-navbar .navbar-container .align-items-center .header_left .nav-item{
    display: block !important;
  }
  .header-navbar .navbar-container .align-items-center .header_left .nav-item div span:first-child{
    line-height: 1;
  }
  .header-navbar .navbar-container .align-items-center .header_left .nav-item img{
    margin-bottom: 10px;
  }
  .header-navbar .navbar-container{
    display: block !important;
    min-height: 175px;
  }
  .header-navbar .navbar-container .header_right .dropdown-user .dropdown-user-link .user-nav{
    display: block !important;
  }
  .header-navbar .navbar-container .header_right{
    margin-top: 10px;
  }
  .top_part_responsive{
    margin-top: 195px !important;
  }
  .top_part_responsive .whole_part{
    display: inline-block;
    height: auto;
  }
  .top_part_responsive .whole_part .right_part .right_part_right_whole{
    /*display: inline-block;*/
    
    display: -webkit-box;
    overflow-x: scroll;
  }
  .top_part_responsive .whole_part .right_part .right_part_right_whole .summary_bordered_box{
    min-height: 130px;
    height: auto !important;
    margin-top: 15px !important;

    width: 250px !important;
  }
  .top_part_responsive .whole_part .right_part .right_part_right{
    margin-top: 140px;
  }
  .top_part_responsive .whole_part .right_part{
    width: 100%;
  }
  .tab_content_components .ad_pref_part .create_admission_preference{
    overflow-x: scroll;
  }
  .tab_content_components .ad_pref_part .create_admission_preference .ad_pref_item_header, .tab_content_components .ad_pref_part .create_admission_preference .scrolled_content_part{
    width: fit-content;
  }
  .tab_content_components .enrollments .enrollment_right_responsive{
    padding-right: 0;
    margin: 15px 0;
  }
  .tab_content_components .enrollments .enrollment_prog_responsive{
    overflow-x: scroll;
  }
  .tab_content_components .enrollments .enrollment_prog_responsive .box-typical-header{
    width: max-content;
  }
  .student-full-profile-stages #full_profile_stage_part #stages{
    height: auto;
  }
}


@media only screen and (min-width: 768px) {
  .header-navbar .fa-bars{
    display: none;
  }
  .header-navbar .navbar-container .align-items-center .header_left .nav-item{
    display: flex !important;
  }
}

</style>
