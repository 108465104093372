<template>
<section class="main-header">
    <!-- {{ windowWidth }} -->
    <!-- BEGIN: Header-->
    <nav :class="getMainNavClasses" class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-brand-center" data-nav="brand-center">
        <div class="navbar-container d-flex content">
            <div class="d-flex align-items-center">
                <ul class="nav navbar-nav d-xl-none mr-50">
                    <li class="nav-item">
                        <!-- <a class="nav-link menu-toggle" href="javascript:void(0);">
                            <i class="ficon" data-feather="menu"></i>
                        </a> -->
                        <i class="fa fa-bars font-size-20 hand text-uclan" @click="toggle_modal_form()"></i>
                    </li>
                </ul>
                <ul class="nav navbar-nav mr-auto header_left">
                    <li class="nav-item d-flex">
                        <router-link to="/dashboard" class="navbar-brand" href="#">
                            <!-- <img :src="cn(store_uni, 'logo_url', '-')" height="50" v-on:error="img_on_error"/> -->

                            <!-- <img :src="cn(store_auth_user, 'lead.company.logo_url')" height="40" v-on:error="img_on_error"/> -->

                            <img :src="cn(store_auth_user, 'lead.company.logo_url')" style="max-width: 130px; height: 35px;" v-on:error="img_on_error"/>

                        </router-link>
                        <div style="align-self: center;">
                            <span v-html="cn(store_auth_user, 'lead.company.name')" style="font-weight: 600; font-size: 18px;"></span> <!-- <br>
                            <a :href="cn(store_auth_user, 'lead.company.website')" target="_blank" class="link_hover">
                                <span v-html="cn(store_auth_user, 'lead.company.website')"></span>
                            </a> -->
                            <br>
                            <span style="background-image: linear-gradient(to right, rgb(46 17 83 / 19%), rgb(245, 244, 253)); color: rgb(46, 17, 83); border-radius: 20px; padding: 1px 10px; font-size: 11px; font-weight: 400; line-height: 1.3; letter-spacing: .2px; display: inline-block;" v-if="store_auth_user.lead && store_auth_user.lead.office" v-html="cn(store_auth_user, 'lead.office.city') + ' Office'"></span>
                            <!-- &bull;
                            <span style="background-image: linear-gradient(to right, rgb(46 17 83 / 19%), rgb(245, 244, 253)); color: rgb(46, 17, 83); border-radius: 20px; padding: 1px 10px; font-size: 11px; font-weight: 400; line-height: 1.3; letter-spacing: .2px; display: inline-block;"
                            v-if="store_auth_user.lead && store_auth_user.lead.office"
                            v-html="cn(store_auth_user, 'lead.office.name')"></span> -->
                        </div>
                        
                    </li>
                </ul>
            </div>

            <!-- <div class="nav navbar-nav align-items-center ml-auto">
                <div class="dropdown tooltips" style="float: right; margin-top: 0px;" title="" data-original-title="Go To Billing">
                    <i class="fa fa-gbp change_pass_btn billing_verification hand for_billing_hover" data-title="Varification" style="margin-top: 1px; font-size: large;padding: 5px 9px;"></i>
                </div>
                <span v-if="store_auth_user.lead" v-html="cn(store_auth_user, 'lead.name', 'User')" style="font-weight: 600; font-size: 18px;"></span>
            </div> -->

            <ul class="nav navbar-nav align-items-center ml-auto header_right">
                <li class="nav-item dropdown dropdown-notification" :class="flag.show_bell_notifications?'show':''" v-click-outside="close_bell_notifications">
                    <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown" v-on:click="toggle_bell_notifications()">
                        <!-- <i class="ficon" data-feather="bell"></i> -->
                        <i class="fa fa-bell change_pass_btn"></i>
                        <span class="badge badge-pill badge-danger badge-up" v-if="bellNotifications.length" v-html="bellNotifications.length"></span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right bell-n-dropdown-ul" :class="flag.show_bell_notifications?'show':''">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
                                <div class="badge badge-pill badge-light-primary" v-if="bellNotifications.length">
                                    <span v-html="bellNotifications.length"></span> New
                                </div>
                            </div>
                        </li>
                        <li class="scrollable-container media-list" v-if="bellNotifications.length">
                            <a class="d-flex" v-for="(row, index) in bellNotifications" :key="index">
                                <div class="media d-flex align-items-start">
                                    <!-- <div class="media-left">
                                        <div class="avatar bg-light-primary">
                                            <div class="avatar-content" v-html="avatarText(row.creator)"></div>
                                        </div>
                                    </div> -->
                                    <div class="media-body">
                                        <p class="media-heading">
                                            <!-- <span class="font-weight-bolder text-uclan" v-if="row.url" v-on:click="goto_url(row)" v-html="row.comment"></span> -->
                                            <span class="font-weight-bolder" v-html="row.comment?row.comment:row.changedTo?'Application status has been changed to '+row.changedTo:' '"></span>
                                        </p>
                                        <!-- <small class="notification-text">
                                            <i class="fa fa-user-o mr-25" aria-hidden="true"></i>
                                            <span v-html="row.creator"></span>
                                            <i class="fa fa-clock-o mx-25" aria-hidden="true"></i>
                                            <span v-html="dNow(row.created_at)"></span>
                                        </small> -->
                                    </div>
                                    <div class="my-auto">                               
                                        <i class="fa fa-times-circle text-secondary font-size-18" aria-hidden="true" v-tooltip="'Close'" v-on:click="bell_notification_mark_as_read(row.id, index)"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li v-show="!bellNotifications.length" class="dropdown-menu-footer text-center">
                            <strong>
                                <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                                Found no notification.
                            </strong>
                        </li>
                        <!-- <li class="dropdown-menu-footer"><a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a></li> -->
                    </ul>
                </li>

                <li class="nav-item dropdown dropdown-notification mr-25">
                    <!-- <router-link to="/change-password" class="nav-link" href="javascript:void(0);" data-toggle="dropdown">
                        <i class="fa fa-cog change_pass_btn" style="padding: 5px 7px;"></i>
                    </router-link> -->
                    <span @click="$refs.change_password.show()" class="nav-link hand">
                        <i class="fa fa-cog change_pass_btn" style="padding: 5px 7px;"></i>
                    </span>
                </li>
                
                
                <li class="nav-item dropdown dropdown-notification mr-25" style="margin-right: 35px !important;">
                    <!-- <router-link to="/logout" class="">
                        <i class="fa fa-sign-out change_pass_btn"></i>
                    </router-link> -->
                    <span class="hand" @click="logout()">
                        <i class="fa fa-sign-out change_pass_btn"></i>
                    </span>
                </li>

                <li class="nav-item dropdown dropdown-user" :class="flag.acc_dropdown?'show':''">
                    <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" v-on:click="acc_dropdown()"  v-click-outside="close_acc_dropdown">

                        <div class="user-nav d-sm-flex d-none">
                            <!-- <span class="user-name font-weight-bolder" v-html="cn(store_auth_user, 'name', 'User')"></span> -->
                            <!-- <span class="user-name font-weight-bolder" v-if="store_auth_user.lead" v-html="cn(store_auth_user, 'lead.name', 'User')"></span> -->
                            <span v-if="store_auth_user.lead" v-html="cn(store_auth_user, 'lead.name', 'User')" style="font-weight: 600; font-size: 18px; cursor: default;"></span>
                            <!-- <span class="user-status">Admin</span> -->
                        </div>
                        
                        <span class="avatar d-none">
                            <!-- <img class="round" :src="url('static/vuexy/images/no_avatar.png')" v-on:error="img_on_error" alt="avatar" height="40" width="40"/> -->
                            <img class="round" :src="cn(store_auth_user, 'profile_photo')" v-on:error="img_on_error" alt="avatar" height="40" width="40"/>
                            <span class="avatar-status-online"></span>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right d-none" :class="flag.acc_dropdown?'show':''" aria-labelledby="dropdown-user">
                        <!-- <a class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="user"></i> Profile</a> -->
                        <!-- <a v-on:click="logout()" class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="power"></i> Logout</a> -->
                        <!-- <router-link to="/user/change-password" class="dropdown-item">
                            <i class="mr-50" data-feather="key"></i> Change Password
                        </router-link> -->
                        <!-- <router-link to="/logout" class="dropdown-item">
                            <i class="mr-50" data-feather="power"></i> Logout
                        </router-link> -->
                        <a v-on:click="logout()" class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="power"></i> Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
    <ul class="main-search-list-defaultlist-other-list d-none">
        <li class="auto-suggestion justify-content-between">
            <a class="d-flex align-items-center justify-content-between w-100 py-50">
                <div class="d-flex justify-content-start">
                    <span class="mr-75" data-feather="alert-circle"></span>
                    <span>No results found.</span>
                </div>
            </a>
        </li>
    </ul>
    <!-- END: Header-->

    <!-- BEGIN: Main Menu-->
    <!-- <div class="horizontal-menu-wrapper">
        <div class="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border" role="navigation">
            <div class="navbar-header">
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item mr-auto">
                    </li>
                    <li class="nav-item nav-toggle"><a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i></a></li>
                </ul>
            </div>
            <div class="shadow-bottom"></div>
            <div class="navbar-container main-menu-content" data-menu="menu-container">

                <ul class="nav navbar-nav navbar-right" id="main-menu-navigation" data-menu="menu-navigation">
                    <template v-for="row in list.nav_menu">
                        <li v-if="permission_check(row.permission)" class="dropdown nav-item" data-menu="dropdown" :key="row.id" v-on:mouseover="open_nav_submenu(row)" v-on:mouseleave="close_nav_submenu(row)" :class="active_nav(row)">
                                <router-link v-if="row.url" :class="row.children?'dropdown-toggle':''" class="nav-link d-flex align-items-center" :to="row.url">
                                    <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                    <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                    <span v-html="row.label"></span>
                                </router-link>
                                <a v-else :class="row.children?'dropdown-toggle':''" class="nav-link d-flex align-items-center" href="javascript:void(0)">
                                    <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                    <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                    <span v-html="row.label"></span>
                                </a>
                            <ul class="dropdown-menu" :class="row.class ? 'settings-dropdown' : ''" v-if="row.children">
                                <template v-for="inner_row in row.children">
                                    <li v-if=" permission_check(inner_row.permission)" :key="inner_row.id">
                                        <div>
                                            <router-link v-if="inner_row.url" class="dropdown-item d-flex align-items-center" :to="inner_row.url" :class="active_nav(inner_row)">
                                                <i v-if="!inner_row.icon_type" :data-feather="inner_row.icon"></i>
                                                <i v-if="inner_row.icon_type=='fontawesome'" :class="inner_row.icon"></i>
                                                <span v-html="inner_row.label"></span>
                                            </router-link>
                                            <a v-else class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                                <i :data-feather="inner_row.icon"></i>
                                                <span v-html="inner_row.label"></span>
                                            </a>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- END: Main Menu-->

    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-body" style="min-height: 70vh;">
                <slot></slot>
            </div>

        </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>

    <!-- BEGIN: Footer-->
    <footer class="footer footer-static footer-light">
        <p class="clearfix mb-0"><span class="float-md-left d-block d-md-inline-block mt-25">COPYRIGHT &copy; SAMS CRM {{ dYear(new Date()) }}</span></p>
    </footer>
    <button class="btn btn-primary btn-icon scroll-top" type="button"><i data-feather="arrow-up"></i></button>
    <!-- END: Footer-->

            
        <b-modal ref="change_password" centered hide-footer>
            <!-- <template #modal-title>
                <span class="text-primary">Change Password</span>
            </template> -->

            <template #modal-header>
              <h5 class="modal_title">Change Password</h5>
              <button type="button" @click="$refs.change_password.hide()">
                    <i class="font-icon-close-2"></i>
                </button>
            </template>

            <div class="wait_me_insert_form change_pass_form">
                <div style="padding: 10px 15px 0px; margin: 10px 15px 15px; border-radius: 0.25rem; background: #fff;">
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form_label" for="old_password">Old password <span class="text-danger">*</span></label>
                            <input type="password" v-model="form.user.old_password" class="form-control" id="old_password" placeholder="Enter old password" @keyup.enter="resetPassword()" autocomplete="off"/>
                        </div>
                        <div class="form-group">
                            <label class="form_label" for="password">New password <span class="text-danger">*</span></label>
                            <input type="password" v-model="form.user.password" class="form-control" id="password" placeholder="Enter new password" v-on:keyup="password_validation_check()" @keyup.enter="resetPassword()" autocomplete="off"/>
                        </div>
                        <div class="form-group">
                            <label class="form_label" for="confirm_password">Confirm password <span class="text-danger">*</span></label>
                            <input type="password" v-model="form.user.confirm_password" class="form-control" id="confirm_password" placeholder="Enter confirm password" v-on:keyup="password_validation_check()" @keyup.enter="resetPassword()" autocomplete="off"/>
                        </div>
                        <div class="form-group">
                            <span>
                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.character"></check-icon>
                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.character"></x-icon>
                                At least 8 character long.
                            </span><br>
                            <span>
                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.lowercase"></check-icon>
                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.lowercase"></x-icon>
                                One lowercase character
                            </span><br>
                            <span>
                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.uppercase"></check-icon>
                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.uppercase"></x-icon>
                                One uppercase character
                            </span><br>
                            <span>
                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.number"></check-icon>
                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.number"></x-icon>
                                One Number
                            </span><br>
                            <span>
                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.symbol"></check-icon>
                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.symbol"></x-icon>
                                One special character
                            </span><br>
                            <span>
                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.confirm_password"></check-icon>
                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.confirm_password"></x-icon>
                                Confirm password not match.
                            </span>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col text-right pr-2">
                    <button class="submit_button" @click.prevent="resetPassword()">Change Password</button>
                </div>
            </div>
            </div>
        </b-modal>


        <!-- <div class="row student_edit_sidebar_form">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <sidebar-form 
                    :show_sidebar="flag.show_right_notification_modal"
                    v-on:toggle-sidebar="toggle_modal_form()"
                    title="Edit Student"
                    >
                    <div class="mb-1">
                        <h5 class="">
                            <span class="align-middle">Edit Student</span>
                        </h5>
                    </div>
                    <div class="flex-grow-1 wait_me_edit_form">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="edit_intake_name"><b>First/Given Name</b> <span class="text-danger">*</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </sidebar-form>
            </div>
        </div> -->

        <!-- <div class="slide_popup_view">
          <div class="slide_popup_inner_view">
            <div class="slide_bottom_popup_details scroll_bar">

                <div class="slide_details_content">
                    <div class="single_radius_list_item_header">
                        Unread Notifications
                        <span class="badge badge-success ml-2 list_counter">
                           7
                        </span>
                    </div>

                    <div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div>
                    <div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div><div class="single_radius_item" style="">
                       <i class="fa fa-list-ul"></i> 
                       <span> 
                       Requirement document updated by student.
                       <br>
                       <small>09 Jun 2022, 09:57 AM</small>
                       </span> 
                    </div>
                </div>
                <i class="fa fa-close slide_close_button_action add_hover"></i>
                <a href="#" class="btn submit_button">See More</a>
            </div>
          </div>  
          <div class="slide_popup_open_bg_overlay_view"></div>
        </div> -->



</section>
</template>

<script>
import feather from 'feather-icons';
// import { Slide } from 'vue-burger-menu'
// import SidebarForm from '../SidebarForm';
import { BModal } from 'bootstrap-vue';
import { CheckIcon, XIcon } from 'vue-feather-icons'

export default{
    name: 'MainLayout',
    // props: {
    //     toggleVerticalMenuActive: {
    //         type: Function,
    //         default: () => {},
    //     },
    // },
    components:{
        // Slide
        // SidebarForm,
        BModal,
        CheckIcon, XIcon
    },
    data:()=>{
        return {
            windowWidth: window.innerHeight,
            success: false,
            list:{
                nav_menu: [
                    {
                        id:'dashboard',
                        label:'Dashboard',
                        icon: 'home',
                        url: '/dashboard',
                        expand: false,
                        children: null,
                        permission: 'permitted',
                    },
                ],
                bell_notifications:[]
            },
            flag:{
                acc_dropdown: false,
                show_bell_notifications: false,
                show_modal_form:false,
                show_right_notification_modal: false,
            },
            scrollingUp: false,
            scrollingDown: false,
            prevScrollpos: window.pageYOffset,
            form: {
                user: {
                    name: '',
                    email: '',
                    old_password: '',
                    password: '',
                    confirm_password: '',
                }
            },
            errorMessages: {},
            bellNotifications: []
        };
    },
    methods:{
        toggle_modal_form: function(){
            console.log('sidebar-form')
            this.flag.show_right_notification_modal = !this.flag.show_right_notification_modal;
        },
        acc_dropdown: function(){
            this.flag.acc_dropdown=!this.flag.acc_dropdown;
        },
		logout: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v2/student/auth/logout');

            try{
                await jq.post(url);

                localStorage.removeItem('student_api_token');
                localStorage.removeItem('base_url');
                localStorage.removeItem('setApiToken');
                ref.$store.commit('siteInfo', {
                        key: 'auth_user',
                        val: ''
                    });
                ref.$router.push('/');
            }catch(err){
                console.log(err);
            }
		},
        open_nav_submenu: function(row){
            var ref=this;
            ref.list.nav_menu.forEach(each=>{
                each.expand=false;
            });
            row.expand=true;
        },
        close_nav_submenu: function(row){
            row.expand=false;
        },
        active_nav: function(row){

            var ref=this;
            var route_path=ref.$route.path
            var route_name = ref.$route.name
            // console.log('route_name', route_name)
            var classes='';

            if (row.class) {
                classes += row.class;
            }

            if(row.url && route_path==row.url){
                classes += ' active';
            }else if(row.children){

                var children_urls=row.children.map(each=>{
                    return each.url;
                });

                if(children_urls.includes(route_path)) classes +=' active';

            }
            
            if (row.active_routes && row.active_routes.length) {
                var route_matched = row.active_routes.find(item => item == route_name)
                if (route_matched) {
                    classes += ' active';
                }
            }

            if(row.expand) classes+=' show';

            return classes;

        },
        toggle_bell_notifications: function(){
            console.log('clicked')

            this.flag.show_bell_notifications=!this.flag.show_bell_notifications;

            // if(this.flag.show_bell_notifications){
            //     this.get_bell_notifications();
            // }
            this.toggle_modal_form()

        },
        get_bell_notifications: async function(){

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v2/student/ajax/get_bell_notifications');

            try {
                var res=await jq.get(url);
                // console.log('res.data.bell_notifications', res.data.bell_notifications)
                this.bellNotifications=res.data.bell_notifications;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        close_bell_notifications: function(){
            this.flag.show_bell_notifications=false;
        },
        bell_notification_mark_as_read: async function(id, index){
            
            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v2/student/ajax/set_notification_read_at');
            
            try{

                await jq.post(url, {
                    notification_id: id
                });
                console.log(index)

                // ref.remove_row(ref.bellNotifications, index);
                this.get_bell_notifications()

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        goto_url: function(row){
            if (row.url) {
                // window.location.href = row.url;
                window.open(row.url, '_blank')
            }
        },
        close_acc_dropdown: function(){
            this.flag.acc_dropdown=false;
        },
        scrollNow() {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos == 0) {
                this.scrollingUp = false;
                this.scrollingDown = false;
                return;
            }

            if (currentScrollPos < 0) return; // set offset here

            if (this.prevScrollpos > currentScrollPos) {
                // up
                this.scrollingDown = false;
                this.scrollingUp = true;
            } else {
                // down
                this.scrollingUp = false;
                this.scrollingDown = true;
            }

            this.prevScrollpos = currentScrollPos;
        },
        handleScroll() {
            let doScoll;

            window.onscroll = () => {
                clearTimeout(doScoll);
                doScoll = setTimeout(this.scrollNow, 0); // firing less scroll events
            };
        },
        password_validation_check: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                var res = await jq.post(ref.url('api/v2/student/ajax/password_validation_check'), ref.form.user);
                ref.errorMessages = res
                // console.log('errorMessages', ref.errorMessages)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        resetPassword: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.post(ref.url('api/v2/student/ajax/reset_student_password'), ref.form.user);
                this.$router.push('/dashboard')
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
    },
    computed:{
        store_auth_user:function(){
            return this.$store.state.site.auth_user;
        },
        getMainNavClasses() {
            return {
                'scroll-up': this.scrollingUp,
                'scroll-down': this.scrollingDown,
            };
        },
    },
    mounted: function(){

        var jq=this.jq();
        jq('body').removeClass('blank-page');
        feather.replace();
        // this.list.nav_menu = this.nav_static_menu

        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }

        document.title = this.store_auth_user.name + ' | Dashboard'
        
    },
    created: function(){
        this.setup_urls();
        this.handleScroll();
        this.get_bell_notifications();
    },
    updated: function () {
        feather.replace();
    }
}
</script>

<style type="text/css">

.bookmark-wrapper{
    margin-left:15px;
}    
.navbar-nav{
    margin-right: 7px;
}

.bell-n-dropdown-ul{
    height: auto;
    overflow: auto;
}

.settings-dropdown {
    left: -140px !important
}

ul.mobile_menu {
    /* background: rgb(228, 228, 228); */
    margin-left: -6px;
    /* margin-right: -0px; */
}
ul.mobile_menu li{
    padding: 10px 20px;
}
ul.mobile_menu li a{
    color: rgb(58, 58, 58);
    font-size: 15px;
    font-weight: 400;
    transition: 400ms;
}
ul.mobile_menu li a:hover{
    padding-left: 10px;
}

ul.mobile_menu li a span{
    padding-left: 10px !important;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > a {
    padding: 0.715rem 10px !important;
}
.horizontal-layout.navbar-floating:not(.blank-page) .app-content {
    padding: 81px 28px !important;
}
html .content.app-content {
    padding: 0;
}
.scroll-up {
    /* background: green !important; */
}
/* .scroll-down {
    background: red !important;
} */


.dashboard{
    background: linear-gradient(3deg, #f8f8f8 20%, rgb(0 59 255 / 10%) 100%, #f8f8f8 30%);
    height: 500px;
    background-repeat: no-repeat;
}
.header-navbar{
    /*background: transparent !important;*/
    background: #fff !important;
}
.header-navbar.navbar-shadow {
    /*box-shadow: none !important;*/
}
.horizontal-layout.navbar-floating .header-navbar-shadow{
    display: none;
}
/* .header-navbar.nav-sticky {
    background: #fff !important;
    box-shadow: rgb(216 226 231) 0px 1px 0px 0px !important;
} */
.scroll-up {
    background: #fff !important;
    box-shadow: rgb(216 226 231) 0px 1px 0px 0px !important;
    /* box-shadow: rgb(191 191 191) 1px 3px 11px 4px !important; */
    transition: all 0.5s ease;
}
.scroll-down {
    background: #fff !important;
    box-shadow: rgb(216 226 231) 0px 1px 0px 0px !important;
    /* box-shadow: rgb(191 191 191) 1px 3px 11px 4px !important; */
    transition: all 0.5s ease;
}
.link_hover:hover {
    color: #ff7f00 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.header-navbar{
  font-family: 'Proxima Nova', sans-serif !important;
}
.change_pass_btn {
    margin-top: 0;
    /* color: #0082c6; */
    color: #2e1153;
    border: 1px solid #dedbf1;
    border-radius: 50%;
    padding: 5px 6px;
    margin-right: 0;
    background: #dedbf1;
    font-size: 15px !important;
}
.change_pass_btn:hover {
    color: #ff7f00;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #ff7f00 !important;
}
.vue-treeselect__control{
    height: 29px !important;
}
.vue-treeselect__placeholder, .vue-treeselect__single-value{
    line-height: 29px !important;
}
.input-group:not(.bootstrap-touchspin):focus-within .form-control, .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
    border-color: rgba(197, 214, 222, .7) !important;
}
.input-group:not(.bootstrap-touchspin):focus-within{
    box-shadow: none !important;
}
.vue-treeselect__multi-value-item{
    background: #2e1153 !important;
    color: #fff !important;
    border-radius: .25rem !important;
}
.vue-treeselect__value-remove{
    color: #fff !important;
    border-left: none !important;
}
/*.vue-treeselect__menu-container .vue-treeselect__menu .vue-treeselect_list span{
    font-size: 50px !important;
    padding: 20px !important;
    line-height: 20px !important;
}*/
.vue-treeselect__menu-container .vue-treeselect__menu label.vue-treeselect__label{
    font-size: 12px !important;
    padding: 5px 10px !important;
    line-height: 10px !important;
    color: currentColor !important;
}
.vue-treeselect__menu-container .vue-treeselect__menu label.vue-treeselect__label:hover{
    background: #2e1153 !important;
    color: #fff !important;
}

.change_pass_form .form_label{
    color: #5d5d5d !important; 
    font-size: 12px; 
    font-weight: 600; 
    margin-bottom: 6px !important;
}
.change_pass_form .form-control:focus {
    border: solid 1px rgba(197, 214, 222, .7) !important;
    box-shadow: none !important;
    transition: none;
}
.change_pass_form .form-control{
    height: inherit;
}
/*[placeholder]:focus::-webkit-input-placeholder {
  
}*/
/*New popup*/
.slide_bottom_popup_details{
    background: #fff;
    padding: 16px 20px;
    height: 100vh;
}
.slide_bottom_popup_details .slide_close_button_action{
    background: #2e1153;
    padding: 6px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    cursor: pointer;
    color: #fff;
    top: 25px;
    left: -40px;
    position: absolute;
}
/*New popup*/

.slide_popup_view {
  overflow: hidden;
  position: fixed;
  top: 62px;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 0;
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, z-index 0.15;
  -o-transition: opacity 0.15s linear, z-index 0.15;
  transition: opacity 0.15s linear, z-index 0.15;
  z-index: -1;
  z-index: 1;
  overflow-x: hidden;
  /*overflow-y: auto;*/
}

.slide_popup_open_view {
  /*z-index: 99999;*/

  z-index: 999;
  opacity: 1;
  overflow: hidden;
}
.slide_popup_inner_view {
  /*-webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);*/
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: inline-block;
  vertical-align: middle;
  width: 490px;
  /*margin: 30px auto;
  max-width: 97%;*/
  right: 0;
  position: absolute !important;
}
.slide_popup_open_view .slide_popup_inner_view {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  position: relative;
  z-index: 999;
}
.slide_popup_open_view .slide_popup_open_bg_overlay_view {
  background: rgba(0, 0, 0, 0.6);
  /*background: rgb(255 250 250 / 60%);*/
  z-index: 99;
}
.slide_popup_open_bg_overlay_view {
  background: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100%;
  /*position: fixed;*/
  left: 0;
  top: 63px !important;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-transition: background 0.15s linear;
  -o-transition: background 0.15s linear;
  transition: background 0.15s linear;
}



/*Notification list design*/
 .slide_popup_view .scroll_bar {
    overflow: scroll;
  }
 .slide_popup_view .scroll_bar::-webkit-scrollbar {
    width: 7px;
  }
 .slide_popup_view .scroll_bar::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 5px;
  }
 .slide_popup_view .scroll_bar::-webkit-scrollbar-thumb {
    /*background: #00a8ff;*/
    background: linear-gradient(90deg, rgba(183,180,212,1) 0%, rgb(165 164 181) 0%, #fff 75%);
    border-radius: 5px;
  }

.slide_details_content .single_radius_list_item_header {
    background: #dedbf1;
    padding: 7px 16px;
    margin-bottom: 15px;
    border-radius: 30px;
    position: relative;
    font-size: 12px;
    font-weight: 700;
}
.slide_details_content .single_radius_list_item_header .list_counter{
    background: #ffffff;
    font-weight: 700;
    font-size: 11px !important;
    border-radius: 10px;
    padding: 3px 12px !important;
    border: 1px solid #d4d2df;
    color: #fa424a;
    /* top: -1px; */
    /* position: relative; */
    float: right;
    margin-top: -1px;
}
.slide_details_content .single_radius_item {
    display: flex;
    background: fff;
    padding: 7px 16px;
    margin-top: 10px;
    border-radius: .75rem !important;
    border: 1px solid #e9f0f3;
    align-items: center;
    font-size: 13px;
}
.slide_details_content .single_radius_item:hover {
    background: #f5f4fd;
}
.slide_details_content .single_radius_item:first-child{
    margin-top: 5px;
}
.slide_details_content .single_radius_item .fa {
    background: #f1f0fb;
    padding: 5px 5px;
    border-radius: 50%;
    border: 1px solid #ceccda;
    margin: 0 10px 0 -3px !important;
}
.slide_details_content .single_radius_item span small {
    color: rgb(134, 142, 150);
    font-size: 12px !important;
    margin: 0;
}
/*.slide_details_content .single_radius_item span {
    font-weight: 600;
}*/
.slide_bottom_popup_details .submit_button{
    position: fixed;
    right: 25px;
    bottom: 25px;
    line-height: 25px !important;
}
.slide_bottom_popup_details .submit_button:hover{
    color: #2e1153 !important;
}

</style>