<template>
  <section class="dashboard">
    <main-layout>
		<div class="col-lg-12 top_part_responsive" style="padding-left: 0px;padding-right: 0px; margin-top:100px;">
			<div class="card profile_top_part_info">
        <div class="whole_part">
          <div class="left_part">
            <div class="profile-photo" title="140px130px" style="margin-left: 25px">
              <div class="profile_pic" style="border-radius: 0px; -webkit-border-radius: 0px">
                <!-- <img id="blah" src="https://backend.samsapply.co.uk/assets/custom/img/male.png" alt="Lead Image"/> -->
                <img id="blah" :src="cn(studentAllInfo, 'lead.profile_photo')" v-on:error="lead_img_on_error"/>
                <i class="fa fa-camera" @click="toggleUploadButton"></i>
              </div>
            </div>
            <my-upload field="profile_photo"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail"
               v-model="showUploadComponent"
               :width="200"
               :height="200"
               :url="photoUploadUrl"
               :headers="headers"
               langType="en"
               img-format="png"></my-upload>
          </div>
          <div class="right_part">
            <div class="right_part_right">
              <div class="right_part_right_whole">
                <div class="summary_bordered_box" style="line-height: 20px; width: inherit; background: linear-gradient(to bottom, #FFF 50%,#FFF 35%,#dedbf1 80%,#dedbf1 100%); height: 130px; margin: 0 15px 0 0;">
                  <div class="review-block-title mb-0" style="/*margin-bottom: 17px !important;*/" >
                    <span style="color: black;font-size: 15px;" v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.given_name + ' ' + studentAllInfo.lead.family_name"></span>
                  </div>

                  <label class="label badge" style="padding: 0; background: #fff">
                    <label class="label" title="" style="background: #2e1153;padding: 3px 7px;border-top-right-radius: 0;border-bottom-right-radius: 0;font-size: 11px; color:white; border-top-left-radius: 5px; border-bottom-left-radius: 5px;">
                      Query Ref.
                    </label>
                    <!-- <label class="label" title="SAMS Apply" style="background: #fff;padding: 2px 7px 2px 4px;color: #2e1153;font-weight: 600;border-top-left-radius: 0;border-bottom-left-radius: 0;border: 1px solid #ceccda;position: relative;left: -3px;font-size: 11px; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                      v-if="studentAllInfo.lead && studentAllInfo.lead.file_number" v-html="studentAllInfo.lead.file_number">
                    </label> -->
                    <label class="label" title="SAMS Apply" style="background: #fff;padding: 2px 7px 2px 4px;color: #2e1153;font-weight: 600;border-top-left-radius: 0;border-bottom-left-radius: 0;border: 1px solid #ceccda;position: relative;left: -3px;font-size: 11px; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                      v-if="studentAllInfo.lead && studentAllInfo.lead.query_number" v-html="studentAllInfo.lead.query_number">
                    </label>
                  </label>
                  <br />

                  <!-- <span style="font-size:11px;">
                    <span style="color: #868e96">Nationality:</span>
                    <strong v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.nationality_permanent"></strong>
                  </span>
                  <br />
                  <span style="font-size:11px;">
                    <span style="color: #868e96">Gender:</span>
                    <strong v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.gender"> Male </strong>
                  </span>
                  <br /> -->
                  <span style="font-size:11px;" v-if="studentAllInfo.lead && studentAllInfo.lead.funding_status">
                    <span style="color: #868e96">Funding:</span>
                    <span v-if="studentAllInfo.lead.funding_status">
                      <strong v-if="studentAllInfo.lead.funding_status == 1"> Govt. Sponsored </strong>
                      <strong v-else-if="studentAllInfo.lead.funding_status == 2"> Self-Funded </strong>
                      <strong v-else-if="studentAllInfo.lead.funding_status == 3"> Other funding </strong>
                      <strong v-else-if="studentAllInfo.lead.funding_status == 4"> Study Loan </strong>
                      <strong v-else-if="studentAllInfo.lead.funding_status == 5"> Financial Support </strong>
                    </span>
                    <span v-else class="label_badge_radius" v-html="'N/A'" style="background: #f3dadc !important; color: #de1528;'"></span>
                  </span>

                  <div id="profile_potential_status"></div>
                </div>

                <div class="summary_bordered_box"  style="line-height: 20px; width: inherit; background: linear-gradient(to bottom, #FFF 50%,#FFF 35%,#dedbf1 80%,#dedbf1 100%); height: 130px; margin: 0 15px 0 0;">
                  <!-- <div class="left_function_part">
                    <div class="left">
                      <span>
                      <i class="fa fa-folder-open-o mr-1"></i>
                      Document
                      </span>
                    </div>
                    <div class="right">
                      <div class="top_part_status" style="position: relative; width: fit-content; margin-left: 10px;">
                        <span class="top_part_status_name" style="background: rgb(222 244 255) !important; color: rgb(0, 168, 255); padding: 1px 10px;margin-top:5px;">In Process</span>
                      </div>
                    </div>
                  </div>

                  <div class="left_function_part">
                    <div class="left">
                      <span>
                      <i class="fa fa-bolt mr-1"></i>
                      Assessment 
                      </span>
                    </div>
                    <div class="right">
                      <div class="top_part_status" style=" position: relative; width: fit-content; margin-left: 10px;">
                      <span  class="top_part_status_name" style="background: rgb(222 244 255) !important; color: rgb(0, 168, 255); padding: 1px 10px;margin-top:5px;">In Process</span>
                      </div>
                    </div>
                  </div>

                  <div class="left_function_part">
                    <div class="left">
                      <span>
                        <i class="fa fa-check-circle-o mr-1" aria-hidden="true"></i>
                        Subject Verify
                      </span>
                    </div>

                    <div class="right">
                      <div class="top_part_status" style="position: relative; width: fit-content; margin-left: 10px;">
                      <span class="top_part_status_name cls_course_verification open_modal_page hand" style="background: #e7f3ea; color: #0ba52e; padding: 2px 10px; line-height: 15px; display: inline-block;">
                        Verified
                      </span>
                      </div>
                    </div>
                  </div> -->

                  <div class="left_function_part">
                    <div class="left" style="width: 50px;">
                      <span>
                        <!-- <i class="fa fa-check-square-o mr-2" aria-hidden="true"></i> -->
                        <strong>Status: </strong>
                      </span>
                    </div>

                    <div class="right" style="width: calc(100% - 50px);">
                      <div v-if="studentAllInfo.lead" class="top_part_status" style="position: relative; width: fit-content;">
                        <span class="top_part_status_name cls_course_verification open_modal_page hand" style="background: #2e1153; color: #FFF; padding: 1px 10px; display: inline-block;" v-if="studentSubmissionStatus == 'Application is approved'" v-html="studentSubmissionStatus"></span>
                        <span class="top_part_status_name cls_course_verification open_modal_page hand" style="background: #e7f3ea; color: #0ba52e; padding: 1px 10px; display: inline-block;" v-if="studentSubmissionStatus == 'Application is submitted'" v-html="studentSubmissionStatus"></span>
                        <span class="top_part_status_name cls_course_verification open_modal_page hand" style="background: #fff0d6 !important; color: #fdad2a; padding: 1px 10px; display: inline-block;" v-if="studentSubmissionStatus == 'Application not yet submitted'" v-html="studentSubmissionStatus"></span>
                        <span class="top_part_status_name cls_course_verification open_modal_page hand" style="background: #fff0d6 !important; color: #fdad2a; padding: 1px 10px; display: inline-block;" v-if="studentSubmissionStatus == 'Application yet to submit'" v-html="studentSubmissionStatus"></span>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="summary_bordered_box"  style="line-height: 20px; width: inherit; background: linear-gradient(to bottom, #FFF 50%,#FFF 35%,#dedbf1 80%,#dedbf1 100%); height: 130px; margin: 0 15px 0 0;">
                  <!-- <div style="width: fit-content; margin: 0 auto; text-align-last: center; margin-top: 23px;">
                    <span class="text-danger">No supervisor added.</span>
                  </div> -->
                  
                  <span style="display: block; line-height: 18px">
                    <strong>Consultant: </strong><br/>
                    <span style="display: block; line-height: 18px"  v-if="studentAllInfo.lead && studentAllInfo.lead.supervisor_id">
                      <!-- <i class="fa fa-user mr-1"></i> -->
                      <div class="d-flex align-item-center mt-1">
                        <span class="avatar mr-1">
                            <img class="round" :src="studentAllInfo.lead.supervisor.profile_pic" v-on:error="img_on_error" alt="avatar" height="35" width="35"/>
                        </span>
                        <div>
                          <span style="font-weight: 600"  v-html="studentAllInfo.lead.supervisor.name"></span><br />
                          <span style="font-size:12px;" v-html="studentAllInfo.lead.supervisor.email"></span>
                        </div>
                      </div>
                    </span>
                    <span style="font-weight: 600" v-else>N/A</span>
                  </span>
                </div>

                <div class="summary_bordered_box"  style="line-height: 20px; width: inherit; background: linear-gradient(to bottom, #FFF 50%,#FFF 35%,#dedbf1 80%,#dedbf1 100%); height: 130px; margin: 0 15px 0 0;">
                  <div style="display: flex; width: 100%; line-height: normal; margin-bottom: 4px;">
                    <div style="width: 20px;">
                      <i class="fa fa-building-o"></i>
                    </div>
                    <div style="width: calc(100% - 20px);">
                      <strong class="" style="margin-bottom: 3px" v-if="studentAllInfo.lead && studentAllInfo.lead.company" v-html="studentAllInfo.lead.company.name"></strong>
                    </div>
                  </div>
                  <div style="display: flex; width: 100%; line-height: normal; margin-bottom: 4px;">
                    <div style="width: 20px;">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div style="width: calc(100% - 20px);">
                      <span style="font-size:12px;" v-if="studentAllInfo.lead && studentAllInfo.lead.office" v-html="studentAllInfo.lead.office.address + ', ' + studentAllInfo.lead.office.city"></span>
                    </div>
                  </div>
                  <div style="display: flex; width: 100%; line-height: normal; margin-bottom: 4px;" v-if="cn(studentAllInfo, 'lead.office.contact_data.phone')">
                    <div style="width: 20px;">
                      <i class="fa fa-phone"></i>
                    </div>
                    <div style="width: calc(100% - 20px);">
                      <span style="font-size:12px;" v-html="studentAllInfo.lead.office.contact_data.phone_code + studentAllInfo.lead.office.contact_data.phone"></span>
                    </div>
                  </div>
                  <div style="display: flex; width: 100%; line-height: normal; margin-bottom: 4px;" v-if="studentAllInfo.lead && studentAllInfo.lead.office">
                    <div style="width: 20px; align-self: center;">
                      <i class="fa fa-envelope-o"></i>
                    </div>
                    <div style="width: calc(100% - 20px); align-self: center;">
                      <span style="font-size:12px;" v-if="studentAllInfo.lead && studentAllInfo.lead.office" v-html="studentAllInfo.lead.office.email"></span>
                    </div>
                  </div>
                  <div style="display: flex; width: 100%; line-height: normal;" v-if="studentAllInfo.lead && studentAllInfo.lead.office && studentAllInfo.lead.office.parsed_working_hour">
                    <div style="width: 20px; align-self: center;">
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <div style="width: calc(100% - 20px); align-self: center;">
                      <strong style="font-size:12px;" v-if="studentAllInfo.lead && studentAllInfo.lead.office && studentAllInfo.lead.office.parsed_working_hour" v-html="'Office Hour: ' + studentAllInfo.lead.office.parsed_working_hour.start + ' - ' + studentAllInfo.lead.office.parsed_working_hour.end"></strong>
                    </div>
                  </div>
                  <!-- <span class="d-none" style="margin-bottom: -10px; display: block; line-height: 18px;">
                    <i class="fa fa-building-o mr-1"></i>
                    <strong class="" style="margin-bottom: 3px" v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.company.name"></strong>
                    <br />
                    <span style="font-size:12px;" v-if="studentAllInfo.lead && studentAllInfo.lead.office" v-html="studentAllInfo.lead.office.address + ', ' + studentAllInfo.lead.office.city"></span>
                    <br v-if="studentAllInfo.lead && studentAllInfo.lead.office" />
                    <i v-if="studentAllInfo.lead && studentAllInfo.lead.office && studentAllInfo.lead.office.contact_data && studentAllInfo.lead.office.contact_data.phone" class="fa fa-phone mr-1"></i>
                    <span style="font-size:12px;" v-if="cn(studentAllInfo, 'lead.office.contact_data.phone')" v-html="studentAllInfo.lead.office.contact_data.phone_code + studentAllInfo.lead.office.contact_data.phone"></span>
                    <br v-if="studentAllInfo.lead && studentAllInfo.lead.office && studentAllInfo.lead.office.billing_phone" />
                    <i v-if="studentAllInfo.lead && studentAllInfo.lead.office" class="fa fa-envelope-o mr-1"></i>
                    <span style="font-size:12px;" v-if="studentAllInfo.lead && studentAllInfo.lead.office" v-html="studentAllInfo.lead.office.email"></span>
                    <br v-if="studentAllInfo.lead && studentAllInfo.lead.office"/>
                    <i v-if="studentAllInfo.lead && studentAllInfo.lead.office && studentAllInfo.lead.office.parsed_working_hour" class="fa fa-calendar-check-o mr-1"></i>
                    <strong style="font-size:12px;" v-if="studentAllInfo.lead && studentAllInfo.lead.office && studentAllInfo.lead.office.parsed_working_hour" v-html="'Office Hour: ' + studentAllInfo.lead.office.parsed_working_hour.start + ' - ' + studentAllInfo.lead.office.parsed_working_hour.end"></strong>
                  </span> -->
                </div>

                <div class="basic_circle_box action_dropdown d-none" style="text-align: center; position: absolute; top: -5px; margin-right: 0;">
                  <div class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                  style="padding: 0; border: none;background: transparent;">
                    <div style="font-size: 1rem; padding: 5px" class="create_reminder_icon">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div class="dropdown-menu" aria-labelledby="dd-header-add" style="margin-left: -110px">
                  <ul>
                    <li class="dropdown-item open_reminder_form">
                      <span class="hand">Set Reminder</span>
                    </li>
                  </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>

      <div class="row mb-1">
          <div class="col-12 col-lg-3 col-md-3 left_side_content">
            <template v-if="studentAllInfo.lead">
              <div class="card p-1 lead_summary_part profile_at_a_glance">
                <div class="content_title" style="color: black;">
                  <span class="fa fa-eye" style="background: #f5f4fd; padding: 6px; border-radius: 50%; border: 1px solid #d8e2e7;"></span>
                  <strong style="font-size: 17px; font-weight: 500;">Profile at a Glance</strong>
                </div>

                <div  class="load_primary_information" style="padding: 5px 10px 10px; margin-bottom: 10px;">

                  <div v-if="studentAllInfo.lead.given_name" class="single_glance_row mt-1" role="alert" style="border-top: 1px solid #e8eef1;">
                    <span class="title" v-html="studentAllInfo.lead.given_name + ' ' + studentAllInfo.lead.family_name" ></span> 
                    <!-- <div v-if="studentAllInfo.lead" style="display: initial;">
                      <span v-html="studentAllInfo.lead.given_name + ' ' + studentAllInfo.lead.family_name" style="font-size: 12px; color: #868e96;"></span>
                    </div> -->
                  </div>
                  <div v-if="cn(studentAllInfo, 'lead.birthday', false)" class="single_glance_row mt-1" role="alert">
                    <span class="title">Date Of Birth : </span> 
                    <div v-if="cn(studentAllInfo, 'lead.birthday', false)" style="display: initial;">
                      <span v-html="dDate(studentAllInfo.lead.birthday)" style="font-size: 12px; color: #868e96;"></span>
                      <span v-if="studentAllInfo.lead.birthday"> ({{ ageYearMonthCount(studentAllInfo.lead.birthday) }})</span>
                    </div>
                  </div>
                  <div v-if="studentAllInfo.lead.gender" class="single_glance_row mt-1" role="alert">
                    <span class="title">Gender : </span> 
                    <div style="display: initial;">
                      <span v-if="studentAllInfo.lead.gender" v-html="studentAllInfo.lead.gender" style="font-size: 12px; color: #868e96;"></span>
                    </div>
                  </div>
                  <div v-if="studentAllInfo.lead.marital_status" class="single_glance_row mt-1" role="alert">
                    <span class="title">Marital Status : </span> 
                    <div v-if="studentAllInfo.lead" style="display: initial;">
                      <span style="font-size: 12px; color: #868e96;">{{ studentAllInfo.lead.marital_status }}</span>
                    </div>
                  </div>
                  <div v-if="studentAllInfo.lead.nationality" class="single_glance_row mt-1" role="alert">
                    <span class="title">Nationality : </span> 
                    <div  style="display: initial;">
                      <span v-if="studentAllInfo.lead.nationality" v-html="studentAllInfo.lead.nationality.name" style="font-size: 12px; color: #868e96;"></span>
                    </div>
                  </div>



                  <!-- <dd>Given Name</dd>
                  <dt v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.given_name"></dt>
                  <dd>Family Name</dd>
                  <dt v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.family_name"></dt>
                  <dd>Date Of Birth</dd>
                  <dt v-if="studentAllInfo.lead">
                    <span v-if="studentAllInfo.lead.birthday" v-html="dDate(studentAllInfo.lead.birthday)"></span>
                    <span v-if="studentAllInfo.lead.birthday"> ({{ ageFullCount(studentAllInfo.lead.birthday) }})</span>
                  </dt>
                  <dd>Marital Status</dd>
                  <dt v-if="studentAllInfo.lead">
                    {{ studentAllInfo.lead.marital_status }}
                  </dt> -->
                </div>

                <div class="summary_edu_part" v-if="educationItem">
                  <div class="education_head">
                    <div class="left" v-if="educationItem.level" >
                      <span class="edu_title" v-html="educationItem.level.name"></span>
                    </div>
                    <div class="right" v-if="educationItem.result">
                      <span class="label_badge_radius" style="font-weight: 600; background: #fff;"  v-html="educationItem.result"></span>
                    </div>
                  </div>
                  <div class="education_body mt-1">
                    <ul>
                      <li v-if="educationItem.subject" v-html="educationItem.subject"></li>
                      <li v-if="educationItem.institution" v-html="educationItem.institution"></li>
                      <li v-html="educationItem.start_month + ' ' + educationItem.start_year + ' - ' + educationItem.end_month + ' ' + educationItem.end_year"></li>
                    </ul>
                  </div>
                </div>

                <div style="padding: 5px 10px 10px; margin-bottom: 10px;">
                  <div v-if="leadEnglishTestPrimary" class="single_glance_row mt-1">
                      <div v-if="leadEnglishTestPrimary.language_test.short_name">
                        <span class="label_badge_primary mr-50">
                          {{ leadEnglishTestPrimary.language_test.short_name }}
                        </span>
                        <span class="text-secondary">
                          <i class="fa fa-calendar-check-o mr-50"></i>
                          <span v-tooltip="'Expiry Date'">({{ dDate(leadEnglishTestPrimary.expiration_date) }})</span>
                        </span>
                        <span v-if="getOverAllData(leadEnglishTestPrimary)" v-tooltip="'Overall Score'" class="label_badge_radius pull-right">
                          {{ getOverAllData(leadEnglishTestPrimary) }}
                        </span>
                      </div>
                      <div class="mt-50 font-size-11" v-if="getOtherTestData(leadEnglishTestPrimary) && getOtherTestData(leadEnglishTestPrimary).length">
                          <span v-for="(testItem, index) in getOtherTestData(leadEnglishTestPrimary)" :key="index">
                            <span>
                              {{ testItem.language_test.name }}: <b>{{ testItem.result }}</b>
                              <span v-if="getOtherTestData(leadEnglishTestPrimary).length != (index + 1)">,</span>
                            </span>
                          </span>
                      </div>
                    <!-- <span class="label_badge_radius" v-else v-html="'N/A'" style="background: #f3dadc !important; color: #de1528;'"></span> -->
                  </div>
                  <div v-if="studentAllInfo.lead.lead_work_history && studentAllInfo.lead.lead_work_history.length" class="single_glance_row mt-1">
                    <span class="title">Work Exp : </span> 
                    <div style="display: initial;" v-if="studentAllInfo.lead.lead_work_history.length">
                      <span class="label_badge_radius badge-lighter-green" style="margin-left: 3px; margin-right: 5px;">{{ countWorkExperience(studentAllInfo.lead.lead_work_history) }}</span>
                      <!-- <span style="font-size: 12px; color: #868e96;">(02 Feb 2015 - Continue)</span> -->
                      <span  style="font-size: 12px; color: #868e96;">({{ getWorkExperienceDuration(studentAllInfo.lead.lead_work_history) }})</span>
                    </div>
                    <span class="label_badge_radius" v-else v-html="'N/A'" style="background: #f3dadc !important; color: #de1528;'"></span>
                  </div>
                  <div v-if="studentAllInfo.lead.need_visa != null" class="single_glance_row mt-1">
                    <span class="title">Visa Needed : </span> 
                    <div style="display: initial;">
                      <span class="label_badge_radius"
                                v-html="studentAllInfo.lead.need_visa ? 'Yes' : 'No'"
                                :style="studentAllInfo.lead.need_visa ? 'background: #d8fdd8; color: green;' : 'background: #f3dadc !important; color: #de1528;'"
                                ></span>
                    </div>
                  </div>
                  <div v-if="needLeadVisa && needLeadVisa.visa_type" class="single_glance_row mt-1">
                    <span class="title">Visa Type : </span> 
                    <div v-if="studentAllInfo.lead" style="display: initial;">
                      <span style="font-size: 12px; color: #868e96;" v-html=" needLeadVisa.visa_type.name"></span>
                    </div>
                  </div>
                  <div v-if="needLeadVisa && needLeadVisa.country_info" class="single_glance_row mt-1">
                    <span class="title">Country of App : </span> 
                    <div style="display: initial;">
                      <span style="font-size: 12px; color: #868e96;" v-html="needLeadVisa.country_info.name"></span>
                    </div>
                  </div>
                  <div v-if="stageItems && stageItems.visa_history_and_refusal" class="single_glance_row mt-1">
                    <span class="title">Visa History : </span> 
                    <div v-if="studentAllInfo.lead.has_visa_history != null" style="display: initial;">
                      <span class="label_badge_radius"
                                :class="studentAllInfo.lead.has_visa_history ? 'badge-lighter-green' : 'badge-lighter-red'"
                                v-html="studentAllInfo.lead.has_visa_history ? 'Yes' : 'No'"
                                ></span>
                    </div>
                    <span class="label_badge_radius" v-else v-html="'N/A'" style="background: #f3dadc !important; color: #de1528;'"></span>
                  </div>
                  <div v-if="stageItems && stageItems.visa_history_and_refusal" class="single_glance_row mt-1" role="alert">
                    <span class="title">Prev Visa Refusal : </span> 
                    <div v-if="studentAllInfo.lead.has_visa_refusal != null" style="display: initial;">
                      <span class="label_badge_radius"
                          :class="studentAllInfo.lead.has_visa_refusal ? 'badge-lighter-green' : 'badge-lighter-red'"
                                v-html="studentAllInfo.lead.has_visa_refusal ? 'Yes' : 'No'"
                                ></span>
                    </div>
                    <span class="label_badge_radius" v-else v-html="'N/A'" style="background: #f3dadc !important; color: #de1528;'"></span>
                  </div>
                  <div v-if="stageItems && stageItems.passport_details" class="single_glance_row mt-1" role="alert" style="border-bottom: none;">
                    <span class="title">Passport No : </span> 
                    <div v-if="studentAllInfo.lead.passports && studentAllInfo.lead.passports.length" style="display: initial;">
                      <span v-if="getLastItemOfArray(studentAllInfo.lead.passports)" v-html="getLastItemOfArray(studentAllInfo.lead.passports).passport_number"></span> 
                      <span style="font-size: 12px; color: rgb(134, 142, 150);" v-if="getLastItemOfArray(studentAllInfo.lead.passports) && getLastItemOfArray(studentAllInfo.lead.passports).expire_date"> ({{ getExpireDateLeft(getLastItemOfArray(studentAllInfo.lead.passports).expire_date) }})</span>
                    </div>
                    <span class="label_badge_radius" v-else v-html="'No'" style="background: #f3dadc !important; color: #de1528;'"></span>
                  </div>
                </div>

              </div>

              <!-- <div class="card p-1 lead_summary_part profile_at_a_glance">

                <div class="stage_form_header">
                  <div class="left">
                    <i class="font-icon font-icon-user" aria-hidden="true" style="padding: 5.5px 7.5px;"></i>
                  </div>
                  <div class="right" style="align-self: center;">
                      <span class="main_title" v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.given_name + ' ' + studentAllInfo.lead.family_name"></span>
                      <span class="sub_title" v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.city"></span>
                  </div>
                </div>

                <div class="load_primary_information" style="border-bottom: 0.5px dashed #e3e8ec; padding-bottom: 10px; margin-bottom: 15px;">
                  <dd>Given Name</dd>
                  <dt v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.given_name"></dt>
                  <dd>Family Name</dd>
                  <dt v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.family_name"></dt>
                  <dd>Date Of Birth</dd>
                  <dt v-if="studentAllInfo.lead">
                    <span v-if="studentAllInfo.lead.birthday" v-html="dDate(studentAllInfo.lead.birthday)"></span>
                    <span v-if="studentAllInfo.lead.birthday"> ({{ ageFullCount(studentAllInfo.lead.birthday) }})</span>
                  </dt>
                  <dd>Marital Status</dd>
                  <dt v-if="studentAllInfo.lead">
                    {{ studentAllInfo.lead.marital_status }}
                  </dt>
                </div>

                <div class="load_primary_information">
                  <div id="title_design_div_new">
                    <div class="left">
                      <div class="content_title"><span class="fa fa-circle"></span><strong>Address</strong></div>
                    </div>
                    <div class="right">
                      
                    </div>
                  </div>
                  <div class="home_address_inner">
                    <div class="home_address_inner_left">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="home_address_inner_right">
                      <dd>Permanent Address</dd>
                      <dt v-if="studentAllInfo.lead" v-html="studentAllInfo.lead.permanent_address" class="hover_for_edit_address mb-0">
                        <span v-if="studentAllInfo.lead.permanent_address" v-html="studentAllInfo.lead.permanent_address"></span>
                        <span v-if="studentAllInfo.lead.permanentcity" v-html="', ' + studentAllInfo.lead.permanentcity"></span>
                        <span v-if="studentAllInfo.lead.zip_code_permanent" v-html="', ' + studentAllInfo.lead.zip_code_permanent"></span>
                        <span v-if="studentAllInfo.lead.nationality_permanent" v-html="', ' + studentAllInfo.lead.nationality_permanent"></span>
                      </dt>
                    </div>
                  </div>
                  <div class="home_address_inner">
                    <div class="home_address_inner_left">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="home_address_inner_right">
                      <dd>Present Address</dd>
                      <dt v-if="studentAllInfo.lead" class="hover_for_edit_address mb-0">
                        <span v-if="studentAllInfo.lead.present_address" v-html="studentAllInfo.lead.present_address"></span>
                        <span v-if="studentAllInfo.lead.city" v-html="', ' + studentAllInfo.lead.city"></span>
                        <span v-if="studentAllInfo.lead.zip_code_present" v-html="', ' + studentAllInfo.lead.zip_code_present"></span>
                        <span v-if="studentAllInfo.lead.nationality_present" v-html="', ' + studentAllInfo.lead.nationality_present"></span>
                      </dt>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="card p-1 lead_summary_part profile_at_a_glance">
                <div class="load_primary_information" style="padding-bottom: 16px; margin-bottom: 15px;border: 1px solid #e9f0f3; padding: 10px;border-radius: .25rem; background: #f5f4fd;">
                  <!-- <div id="title_design_div_new">
                    <div class="left">
                      <div class="content_title"><span class="fa fa-circle"></span><strong>Address</strong></div>
                    </div>
                    <div class="right">
                      
                    </div>
                  </div> -->
                  <div class="home_address_inner">
                    <div class="home_address_inner_left">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="home_address_inner_right" v-if="studentAllInfo.lead">
                      <dd>Permanent Address</dd>
                      <dt class="hover_for_edit_address mb-0"
                        v-if="studentAllInfo.lead.permanent_address || studentAllInfo.lead.permanentcity ||
                        studentAllInfo.lead.zip_code_permanent || studentAllInfo.lead.nationality_permanent"
                      >
                        <span v-if="studentAllInfo.lead.permanent_address" v-html="studentAllInfo.lead.permanent_address"></span>
                        <span v-if="studentAllInfo.lead.permanentcity" v-html="', ' + studentAllInfo.lead.permanentcity"></span>
                        <span v-if="studentAllInfo.lead.zip_code_permanent" v-html="', ' + studentAllInfo.lead.zip_code_permanent"></span>
                        <span v-if="studentAllInfo.lead.nationality_permanent" v-html="', ' + studentAllInfo.lead.nationality_permanent"></span>
                      </dt>
                      <dt v-else class="text-danger">No information found</dt>
                    </div>
                  </div>
                  <div class="home_address_inner">
                    <div class="home_address_inner_left">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="home_address_inner_right" v-if="studentAllInfo.lead">
                      <dd>Present Address</dd>
                      <dt class="hover_for_edit_address mb-0"
                        v-if="studentAllInfo.lead.present_address || studentAllInfo.lead.city ||
                        studentAllInfo.lead.zip_code_present || studentAllInfo.lead.nationality_present"
                      >
                        <span v-if="studentAllInfo.lead.present_address" v-html="studentAllInfo.lead.present_address"></span>
                        <span v-if="studentAllInfo.lead.city" v-html="', ' + studentAllInfo.lead.city"></span>
                        <span v-if="studentAllInfo.lead.zip_code_present" v-html="', ' + studentAllInfo.lead.zip_code_present"></span>
                        <span v-if="studentAllInfo.lead.nationality_present" v-html="', ' + studentAllInfo.lead.nationality_present"></span>
                      </dt>
                      <dt v-else class="text-danger">No information found</dt>
                    </div>
                  </div>
                </div>

                <div class="content_title" style="color: black;">
                  <span class="fa fa-address-book" style="background: #f5f4fd; padding: 7px 8px; border-radius: 50%; border: 1px solid #d8e2e7;"></span>
                  <strong style="font-size: 17px; font-weight: 500;">Contact Information</strong>
                </div>

                <div v-if="studentAllInfo.lead && studentAllInfo.lead.lead_contact" class="info-palate only_contact_detail_div" style="border-bottom: .5px dashed #e3e8ec; padding-bottom: 10px; padding-left: 35px; margin-top: 8px; margin-bottom: 15px;">
                    <dl style="position: relative;"
                    v-if="studentAllInfo.lead.lead_contact.mobile || studentAllInfo.lead.lead_contact.alt_mobile || studentAllInfo.lead.lead_contact.email"
                    >
                        <dt class="hover_for_edit_phone" v-if="studentAllInfo.lead.lead_contact.mobile">
                          <i class="fa fa-phone mr-1"></i>
                          <span v-if="studentAllInfo.lead.lead_contact.mobile_country" v-html="'+'+ studentAllInfo.lead.lead_contact.mobile_country.calling_code"></span>
                          <span v-html="studentAllInfo.lead.lead_contact.mobile"></span>
                        </dt>
                        <dt class="hover_for_edit_alter_phone" v-if="studentAllInfo.lead.lead_contact.alt_mobile">
                          <i class="fa fa-phone mr-1"></i>
                          <span v-if="studentAllInfo.lead.lead_contact.alt_mobile_country" v-html="'+'+ studentAllInfo.lead.lead_contact.alt_mobile_country.calling_code"></span>
                          <span v-html="studentAllInfo.lead.lead_contact.alt_mobile"></span>
                        </dt>
                        <dt class="hover_for_edit_mail" v-if="studentAllInfo.lead.lead_contact.email">
                          <i class="fa fa-envelope-o mr-1"></i>
                          <span v-if="studentAllInfo.lead.lead_contact" v-html="studentAllInfo.lead.lead_contact.email"></span>
                        </dt>
                    </dl>
                    <dt v-else class="text-danger">No information found</dt>
                </div>

                <div class="info-palate mb-0" style="padding-bottom: 10px; padding-left: 35px;">

                  <div id="title_design_div_new" style="display: flex;">
                    <div class="left">
                      <div class="content_title">
                        <strong>Emergency Contact</strong>
                      </div>
                    </div>
                    <div class="right" style="margin-left: auto;">

                    </div>
                  </div>
                  <div v-if="studentAllInfo.lead && studentAllInfo.lead.lead_contact">
                    <dl style="position: relative;" class="only_emg_contact_div mb-0"
                    v-if="studentAllInfo.lead.lead_contact.emr_name || studentAllInfo.lead.lead_contact.emr_mobile || studentAllInfo.lead.lead_contact.emr_email"
                    >
                      <dt style="font-weight: 600; letter-spacing: .1px; margin-bottom: 0;" v-if="studentAllInfo.lead.lead_contact.emr_name">
                        <span v-html="studentAllInfo.lead.lead_contact.emr_name"></span>
                      </dt>
                      <span style="font-weight: 400; color: #868e96; font-size: 13px;" v-if="studentAllInfo.lead.lead_contact.emr_relation" v-html="studentAllInfo.lead.lead_contact.emr_relation"></span>
                  
                        
                      <div style="margin-top: 8px;">
                        <dt class="mb-0" style="font-weight: 400;color: #868e96;font-size: 12px;"  v-if="studentAllInfo.lead.lead_contact.emr_mobile">
                          <i class="fa fa-phone mr-1"></i>
                          <span v-if="studentAllInfo.lead.lead_contact.emr_mobile_country" v-html="'+'+ studentAllInfo.lead.lead_contact.emr_mobile_country.calling_code"></span>
                          <span v-html="studentAllInfo.lead.lead_contact.emr_mobile"></span>
                        </dt>
              
                        <dt class="mb-0" style="font-weight: 400;color: #868e96;font-size: 12px;" v-if="studentAllInfo.lead.lead_contact.emr_email">
                          <i class="fa fa-envelope-o mr-1"></i>
                          <span v-html="studentAllInfo.lead.lead_contact.emr_email"></span>
                        </dt>
                      </div>
                    </dl>
                    <dt v-else class="text-danger">No information found</dt>
                  </div>
                </div>

              </div>
            </template>
          </div>
		
          <div class="col-12 col-lg-9 col-md-9 main_body_content">
                <main-body-content :studentInfo="studentInfo" :studentAllInfo="studentAllInfo" v-on:get_student-data="dashboard_init_data()"/>
          </div>
        </div>


    </main-layout>
  </section>
</template>

<script>
import MainLayout from "./MainLayout.vue";
import feather from "feather-icons";
// import VueApexCharts from 'vue-apexcharts';
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';
// import Treeselect from '@riophae/vue-treeselect';
import MainBodyContent from "./MainBodyContent.vue";
import myUpload from 'vue-image-crop-upload/upload-2.vue';

export default {
  name: "Dashbaord",
  // metaInfo: {
  //   titleTemplate: "%s | Dashbaord",
  // },
  components: {
    MainLayout,
    MainBodyContent,
    'my-upload': myUpload
    // VueApexCharts,
    // BDropdown, BDropdownItem,
    // Treeselect
  },
  data: () => {
    return {
      showUploadComponent: false,
      studentAllInfo: {
        lead: null
      },
      studentInfo: "",
      flag: {
        show_filter: false,
      },
      search: {
        intake_id: null,
      },
      form: {
        lead: {
          profile_photo: ''
        }
      },
      stageItems: {}
    };
  },
  computed: {
    photoUploadUrl: function () {
        return this.base_url + '/api/v2/student/ajax/upload_student_profile_photo';
    },
    headers: function() {
      var headers={
        'Authorization': 'Bearer '+ this.api_token,
        'Access-Control-Allow-Origin' : '*',
        'App-Url': this.base_url ? this.base_url: '#'
      };
        return headers
    },
    educationItem: function () {
      let eductionItemData = ''
      if (this.studentAllInfo.lead) {
        if (this.studentAllInfo.lead.lead_educational_history && this.studentAllInfo.lead.lead_educational_history.length) {
          eductionItemData = this.studentAllInfo.lead.lead_educational_history[0]
        }
      }
      return eductionItemData
    },
    studentSubmissionStatus: function () {
      const user = this.$store.state.site.auth_user
      if (this.studentAllInfo.lead) {
        if (this.studentAllInfo.lead.file_number) {
          // return 'Approved'
          return 'Application is approved'
        } else if (!this.studentAllInfo.lead.file_number && !user.steps_submitted_at) {
          // return 'New'
          // return 'Not Submitted'
          return 'Application not yet submitted'
        }  if (!this.studentAllInfo.lead.file_number && user.steps_submitted_at) {
          // return 'Submitted'
          return 'Application is submitted'
        } else {
          // return 'Pending'
          return 'Application yet to submit'
        }
      } else {
        return ''
      }
    },
    needLeadVisa: function () {
      if (this.studentAllInfo.lead) {
        if (this.studentAllInfo.lead.lead_need_visa) {
          return this.studentAllInfo.lead.lead_need_visa[0]
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    leadEnglishTestPrimary: function () {
      if (this.studentAllInfo) {
        if (this.studentAllInfo.lead) {
          if (this.studentAllInfo.lead.english_tests && this.studentAllInfo.lead.english_tests.length) {
            let testPrimaryItem = ''
            this.studentAllInfo.lead.english_tests.map(item => {
              if (item.is_primary == 1) {
                testPrimaryItem = item
              }
            })
            return testPrimaryItem
          } else {
            return ''
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  watch: {
    // 'search.intake_id': function(intake_id) {
    // 	this.getDataByIntake(intake_id)
    // }
  },
  methods: {
    toggleUploadButton() {
        this.showUploadComponent = !this.showUploadComponent;
    },
    cropSuccess(imgDataUrl, field){
        console.log('-------- crop success --------');
        this.imgDataUrl = imgDataUrl;
        console.log('field: ' + field);
    },
    cropUploadSuccess(jsonData, field){
        console.log('-------- upload success --------');
        console.log(jsonData);
        this.form.lead.profile_photo = jsonData.data.logo_url
        console.log('jsonData.data.success', jsonData.data.success)
        if (jsonData.data.success) {
          this.toggleUploadButton()
          this.dashboard_init_data()
        }
        console.log('field: ' + field);
    },
    cropUploadFail(status, field){
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
    },
    getOverAllData: function (lead_english_test) {
        const resultItem = lead_english_test.results.find(item => item.language_test.id == lead_english_test.language_test_id)
        if (resultItem) {
          return resultItem.result
        } else {
          return ''
        }
    },
    getOtherTestData: function (lead_english_test) {
        const resultItems = lead_english_test.results.filter(item => item.language_test.id != lead_english_test.language_test_id)
        if (resultItems && resultItems.length) {
          return resultItems
        } else {
          return ''
        }
    },
    countWorkExperience: function (items) {
      if (items) {
        const length = items.length
        const lastIndex = length - 1
        const fastItem = items[0]
        const lastItem = items[lastIndex]
        const endtDate = fastItem && fastItem.end_date ? new Date(fastItem.end_date) : new Date()
        const startDate = lastItem && lastItem.start_date ? new Date(lastItem.start_date) : new Date()
        return this.ageCount(startDate, endtDate)
      }
    },
    getWorkExperienceDuration: function (items) {
      if (items) {
        const length = items.length
        const lastIndex = length - 1
        const fastItem = items[0]
        const lastItem = items[lastIndex]
        const endtDate = fastItem && fastItem.end_date ? this.dDate(fastItem.end_date) : 'Continue'
        const startDate = lastItem ? this.dDate(lastItem.start_date) : ''
        if (startDate) {
          return startDate + ' - ' + endtDate
        } else {
          return ''
        }
      }
    },
    getLastItemOfArray: function (items) {
      if (items) {
        const length = items.length
        const lastIndex = length - 1
        return items[lastIndex]
      }
    },
		getExpireDateLeft: function (value) {
			const date = new Date(value)
			let now = new Date();

      let oneDay = 24 * 60 * 60 * 1000;
      let date1InMillis = date.getTime();
      let date2InMillis = now.getTime();
      let days = Math.round(Math.round(date1InMillis - date2InMillis) / oneDay);
      if (days < 0) {
        return `expired ${Math.abs(days)} days ago`
      } else {
        return days + ' Days Left';
      }
		},
    toggle_filter: function () {
      this.flag.show_filter = !this.flag.show_filter;
    },
    dashboard_init_data: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v2/student/ajax/get_student_data');

        try{
          // ref.wait_me('.left_side_content')
          ref.wait_me('.dashboard')
          // const params = { intake_id: intake_id }
          var res=await jq.get(url);
          ref.studentInfo = res.data.student;
          ref.studentAllInfo = res.data;
          this.check_lead_converted_data()
        }catch(error){
          console.log(error);
        }
        finally {
          ref.wait_me('.dashboard', 'hide')
        }
    },
    check_lead_converted_data: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v2/student/ajax/check_lead_menu_converted');

        try{
          ref.wait_me('.left_side_content')
          // const params = { intake_id: intake_id }
          var res=await jq.get(url);
          ref.stageItems =res.data.stages
        }catch(error){
          console.log(error);
        }
        finally {
          ref.wait_me('.left_side_content', 'hide')
        }
    },
		lead_img_on_error: function(ev){
			ev.target.src = 'https://samscrm.co.uk/assets/custom/img/male.png';
		},
  },
  created: function () {
    this.setup_urls();
  },
  mounted: function () {
    feather.replace();
    this.dashboard_init_data();
    // this.check_lead_converted_data();
    document.title = this.cn(this.$store.state.site.auth_user, 'name', 'User Name') + ' | Dashboard'
  },
};
</script>

<style>
/*.dashboard {
	font-size: 10px !important;
}*/

.card-browser-states .browser-states {
  margin-top: 0rem !important;
}
.convert_btn{
    font-size: 13px !important;
  }
.status_name .icon {
    width: 25px;
    background: #ceccda;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.status_name .name {
    padding: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: max-content;
}
#status_drop .dropdown-menu.show{
    /*left: -97px !important;*/
    margin-left: -144px;
}
#status_drop ul li {
    margin: 2px 0;
    padding: 5px 5px;
    cursor: pointer;
}
#status_drop ul li .active_status i, #status_drop ul li .away_status i, #status_drop ul li .inactive_status i {
    font-size: 11px;
}
#status_drop .approved_status:hover {
    background: aliceblue;
}

.status_name{
    color: #fff;
    width: fit-content;
    /*background-image: linear-gradient( to right, #ceccda, #ceccda 15%, #2E58A7 5% );*/
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .2px;
    display: flex;
    margin-left: auto;
    float: right;
  }
  .status_name .icon{
    width: 25px;
    background: #ceccda;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .status_name .name{
    padding: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #status_drop{
    padding: 5px 10px;
    margin-top: 0 !important;
  }
  #status_drop ul li{
    margin: 2px 0;
    padding: 5px 5px;
  }
  #status_drop ul li .active_status i, #status_drop ul li .away_status i, #status_drop ul li .inactive_status i{
    font-size: 11px;
  }
  #status_drop ul li:hover{
    background: aliceblue;
  }
  #status_drop .dropdown-menu.show{
    left: 0px !important;
   }

  .reminder-circle-box {
      width: 30px;
      height: 30px;
      margin-right: 3px;
      border-radius: 50%;
      border: 1px solid darkturquoise;
      position: absolute;
      left: 25%;
      text-align: center;
      background-color: azure;
  }

  .create_reminder_icon {
      color: #625f8b;
      border-radius: 50%;
      padding: 3px;
      font-size: 20px;
      width: 32px;
      height: 32px;
      margin-top: -1px;
      margin-right: -7px;
      cursor: default;
      border: 1px solid #2e58a6;
      cursor: pointer !important;
  }

  .create_reminder_icon:hover {
      color: #ff7f00 !important;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      border: 1px solid #ff7f00;
  }

  .basic_circle_box .btn.dropdown-toggle::after {
    display: none;
  }


  #new_comms_drop_header{
    padding-right: 10px;
    border-right: 1.5px solid #e9ecef;
  }
  #new_comms_drop_header .circle-box{
    border: none;
    left: 15% !important;
  }
  #new_comms_drop_header .dropdown-toggle:after, #req_app_drop_header .dropdown-toggle:after{
    display: none;
  }

  #new_comms_drop_header .dropdown-toggle:after, #review_req_drop_header .dropdown-toggle:after{
    display: none;
  }
  #new_comms_drop_header #new_comms_drop{
    margin-left: -126px;
    margin-top: 10px;
  }
  #new_comms_drop{
    margin-left: -65px;
    padding: 0;
    background: #2e1153;
    border: none;
  }
  #new_comms_drop ul li a,
  #new_comms_drop ul li .open_modal_page{
    font-size: 13px !important;
    font-weight: 300;
    display: block;
  }
  #new_comms_drop ul li a .font-icon,
  #new_comms_drop ul li .open_modal_page .font-icon{
    font-size: 10px !important;
  }
  #new_comms_drop ul li *{
    color: #fff;
  }
  #new_comms_drop ul li:hover{
    background: #6b6865;
  }
  #new_comms_drop ul li:first-child:hover{
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }
  #new_comms_drop ul li:last-child:hover{
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }
  #new_comms_drop ul li{
    padding: 7px 15px;
  }
  #new_comms_drop::after{
    content: '';
    border-bottom: 10px solid #2e58a6;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    transition: all 0.7s;
    transform: rotate(0deg);
    top: -7px;
    right: 7px;
  }
  #req_app_drop_header .circle-box{
    border: none;
    left: 30% !important;
  }

  #review_req_drop_header .circle-box{
    border: none;
    left: 30% !important;
  }
  .review-block-description{
    opacity: .7;
  }

  .edit_note_btn{
    border-radius: 10px;
    padding: 2px 6px !important;
    font-size: 80% !important;
    font-weight: 500;
    background: #c29b5a !important;
    color: #fff !important;
    cursor: pointer;
    border: 1px solid #c29b5a;
    letter-spacing: .2px;
    margin-left: 5px;
  }
  .edit_note_btn:hover {
    background: #fff !important;
    color: #f79322 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #f79322;
}

/* New Style */

.profile_top_part_info{
    background: linear-gradient(to bottom, #FFF 0%,#FFF 55%,#ebe8ff 80%,#eceaf7 100%);
    border-bottom: 1px solid #dedbf1;
    padding: 15px;
  }
  .profile_top_part_info .whole_part{
    width: 100%;
    display: flex;
    /*height: 200px;*/
    height: 145px;
  }
  .profile_top_part_info .whole_part .left_part {
    width: 150px;
    position: relative;
    line-height: 145px;
  }
  .profile_top_part_info .whole_part .left_part .profile-photo {
    left: 48%;
    transform: translate(-50%, 0);
    margin-left: 0 !important;
  }
  .profile_top_part_info .whole_part .left_part .left_circle_box_part {
    margin-top: 145px;
    display: flex;
    justify-content: center;
  }
  .profile_top_part_info .whole_part .right_part {
    width:calc(100% - 150px);
    display: flex;
  }
  .profile_top_part_info .whole_part .right_part .right_part_left {
    width:35%;
    /*margin-top: 30px;*/
    margin-top: 20px;
  }
  .profile_top_part_info .whole_part .right_part .right_part_right {
    width: 100%;
    margin-top: 10px;
  }
  .profile_top_part_info .whole_part .right_part .right_part_left .list-inline {
    display: inline-block !important;
    line-height: 14px;
  }
  .profile_top_part_info .whole_part .right_part .right_part_left .list-inline .rate {
    border: none !important;
    padding: 0 !important;
    height: auto !important;
  }
  .profile_top_part_info .whole_part .right_part .right_part_left .list-inline .rate:not(:checked) > label {
    font-size: 20px !important;
  }
  .profile_top_part_info .whole_part .right_part .right_part_right .right_circle_box_part {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    /*top: 145px;*/
    right: -5px;

    top: 125px;
  }

  .profile_top_part_info .whole_part .right_part .right_part_right .right_circle_box_part .round_check_mark {
    position: relative;
  }
  .profile_top_part_info .whole_part .right_part .right_part_right .right_circle_box_part .round_check_mark label {
    background-color: #dedbf1;
    border: 1px solid #d8e2e7;
    border-radius: 50%;
    cursor: pointer;
    height: 22px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 22px;
  }
  .profile_top_part_info .whole_part .right_part .right_part_right .right_circle_box_part .round_check_mark label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 12px;
  }
  .profile_top_part_info .whole_part .right_part .right_part_right .right_circle_box_part .round_check_mark input[type="checkbox"] {
    visibility: hidden;
  }
  .profile_top_part_info .whole_part .right_part .right_part_right .right_circle_box_part .round_check_mark input[type="checkbox"]:checked + label {
    background-color: #2e1153;
    border-color: #2e1153;
  }
  .profile_top_part_info .whole_part .right_part .right_part_right .right_circle_box_part .round_check_mark input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .profile_top_part_info .whole_part .left_part .profile-photo {
      left: 48%;
      transform: translate(-50%, 0);
      margin-left: 0 !important;
  }

  .profile-photo {
      position: absolute;
  }

  .basic_circle_box {
    position: relative;
    margin-right: 10px;
    text-align: center;
  }

  .right_part_right_whole {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: flex-end;
}

 

  .round_icon_and_drop_image img:hover {
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      border: 1px solid #ff7f00;
  }

  .round_icon_and_drop_image img {
      border: 1px solid #2e1153;
      width: 30px;
      height: 30px;
      border-radius: 40px;
  }

  .right_circle_box_part .round_icon_and_drop .dropdown-toggle {
    color: #868e96;
    cursor: pointer;
    font-size: 16px;
  }

  .round_icon_and_drop .btn-rounded:hover {
      color: #ff7f00 !important;
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      border: 1px solid #ff7f00;
  }
  .round_icon_and_drop .btn-icon {
      width: 30px;
      height: 30px;
      border-color: #2e1153;
  }

  .round_icon_and_drop_family .btn {
    font-size: 1rem;
    padding: 2px;
    color: #2e1153;
}

  .round_icon_and_drop .btn-icon .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: #2e1153;
  }

  .review-block-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px !important;
}

.summary_bordered_box {
    text-align-last: start;
    margin: 10px 20px 0;
    /*border: 1px solid #eceff1;*/
    padding: 15px;
    border-radius: .25rem;
    position: relative;
    /*width: calc(100% - 250px);*/
    /*width: 500px;*/
    width: calc(100% - 310px);

    border: 1px solid #e9f0f3;
  }

  .title_in_border {
    background: white;
    width: fit-content;
    padding: 5px;
    font-size: 13px;
    font-weight: 600;
    top: -14px;
    position: absolute;
    left: 12px;
  }

  .top_right_info_box {
    /*width: 260px;*/
    width: 310px;
    /*background: #f3f1ff;*/
    padding: 10px;
    display: grid;
    /*border: 1px solid #d8e2e7;*/
    border-radius: .25rem;
    /*border-style: dotted;*/

    padding-top: 3px;
  }

  .top_right_info_box table{
    width: -webkit-fill-available;

    height: fit-content;
  }
  .top_right_info_box table tr{
    /*border-top: 1px solid #d8e2e7;
    border-bottom: 1px solid #d8e2e7;*/
  }
  .top_right_info_box table td {
    padding: 2px 10px;
  }
  .top_right_info_box table td:last-child {
    font-weight: 600;
  }
  .top_right_info_box table tr:first-child{
    border-top: none;
  }
  .top_right_info_box table tr:last-child{
    border-bottom: none;
  }

  .notification_icon_new:hover, .create_reminder_icon:hover {
    color: #ff7f00 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    border: 1px solid #ff7f00;
}

.notification_icon_new, .create_reminder_icon {
    color: #2e1153;
    border-radius: 50%;
    padding: 3px;
    font-size: 20px;
    width: 32px;
    height: 32px;
    margin-top: -1px;
    margin-right: -7px;
    cursor: default;
    /* border: 1px solid #2e58a6; */
    border: 1px solid #2e1153;
    cursor: pointer !important;
    background-color: #fff;
}

.profile_pic {
    position: relative;
}

.profile_pic img {
    width: 130px;
    height: 130px;
    border: 2px solid #2e1153;
    border-radius: 1.25rem;
}

.profile_pic i {
    position: absolute;
    border-radius: 50%;
    background: #FFFFFF;
    right: -8px;
    bottom: 0;
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid transparent;
}
.profile_pic i:hover {
  color: #ff7f00 !important;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  border: 1px solid #ff7f00;
}

.action_dropdown .dropdown-toggle:hover .fa-cog{
    color: #ff7f00 !important;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
  /*.action_dropdown .dropdown-menu.show .dropdown-item:hover .font-icon{
    color: #fff;
  }*/
  .change_super_drop .name_text{
    width: 20px; 
    height: 20px; 
    border-radius: 50%; 
    line-height: 20px; 
    text-align: center; 
    background: #f5f4fd; 
    font-weight: 600; 
    font-size: 11px; 
    text-align-last: center; 
    padding: 0;
    border: 1px solid #c1bfcf;
  }
  .change_super_drop img{
    width: 20px; 
    height: 20px; 
    border-radius: 50%;
    padding: 0 !important;
  }
  .top_part_status_name {
    border-radius: 20px;
    /* padding: 4px 22px 4px 12px; */
    padding: 1px 22px 1px 10px;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: .2px;
    top: 0;
    position: relative;
    width: fit-content;
    display: flex;
}
	.left_function_part {
		display: flex;
		width: 100%;
		/* border: 1px solid #f2f3f5; */
		border-radius: 0.25rem;
		/* padding: 2px 5px; */
		font-size:11px;
		line-height: 25px;
	}
	.left_function_part .left {
		width: 125px;
		align-self: center;
		/* border-right: 1px solid #d9dbdf; */
		/* text-align: right; */
		padding-right: 10px;
	}
.tabs-section-nav a .nav-link-in small {
    font-size: 10px !important;
    font-weight: 600;
}

/* left side*/
.lead_summary_part .summary_edu_part {
    /* background: linear-gradient(115deg, #f5f4fd 65%, #dedbf1 35%); */
    background: linear-gradient(115deg, #f5f4fd 65%, #f5f4fd 65%, #dedbf1 90%);
    padding: 15px 10px;
    border-radius: 7px;
    position: relative;
}
.lead_summary_part .summary_edu_part .education_head .left .edu_title, .lead_summary_part .summary_ielts_part .education_head .left .edu_title {
    background: #dedbf1;
    font-weight: 600;
    color: #2e1153;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #d8e2e7;
}
.lead_summary_part .summary_edu_part .education_head {
    display: flex;
    width: 100%;
}
.lead_summary_part .summary_edu_part .education_head .left {
    width: 80%;
}
.lead_summary_part .summary_edu_part .education_head .right {
    width: 20%;
    text-align: right;
}
.lead_summary_part .label_badge_radius {
    background-image: linear-gradient(to right, rgb(46 17 83 / 19%), rgb(245, 244, 253));
    color: rgb(46, 17, 83);
    border-radius: 20px;
    padding: 1px 10px;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: .2px;
}
.lead_summary_part .summary_edu_part .education_body ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}
.lead_summary_part .summary_edu_part .education_body ul li:first-child {
    font-size: 12px;
    font-weight: 600;
    color: black;
}
.lead_summary_part .single_glance_row .title {
    font-size: 12px;
    font-weight: 600;
    color: black;
}


@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Regular.eot);
    src: url(/static/fonts/Proxima_Nova_Regular.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Regular.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Regular.woff) format('woff'), url(/static/fonts/Proxima_Nova_Regular.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Regular.svg#Proxima_Nova_Regular) format('svg');
    font-style: normal;
    font-weight: 400
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Regular_Italic.eot);
    src: url(/static/fonts/Proxima_Nova_Regular_Italic.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Regular_Italic.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Regular_Italic.woff) format('woff'), url(/static/fonts/Proxima_Nova_Regular_Italic.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Regular_Italic.svg#Proxima_Nova_Regular_Italic) format('svg');
    font-style: italic;
    font-weight: 400
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Bold.eot);
    src: url(/static/fonts/Proxima_Nova_Bold.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Bold.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Bold.woff) format('woff'), url(/static/fonts/Proxima_Nova_Bold.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Bold.svg#Proxima_Nova_Bold) format('svg');
    font-style: normal;
    font-weight: 700
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Bold_Italic.eot);
    src: url(/static/fonts/Proxima_Nova_Bold_Italic.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Bold_Italic.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Bold_Italic.woff) format('woff'), url(/static/fonts/Proxima_Nova_Bold_Italic.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Bold_Italic.svg#Proxima_Nova_Bold_Italic) format('svg');
    font-style: italic;
    font-weight: 700
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Semibold.eot);
    src: url(/static/fonts/Proxima_Nova_Semibold.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Semibold.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Semibold.woff) format('woff'), url(/static/fonts/Proxima_Nova_Semibold.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Semibold.svg#Proxima_Nova_Semibold) format('svg');
    font-style: normal;
    font-weight: 600
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Semibold_Italic.eot);
    src: url(/static/fonts/Proxima_Nova_Semibold_Italic.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Semibold_Italic.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Semibold_Italic.woff) format('woff'), url(/static/fonts/Proxima_Nova_Semibold_Italic.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Semibold_Italic.svg#Proxima_Nova_Semibold_Italic) format('svg');
    font-style: italic;
    font-weight: 600
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Light.eot);
    src: url(/static/fonts/Proxima_Nova_Light.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Light.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Light.woff) format('woff'), url(/static/fonts/Proxima_Nova_Light.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Light.svg#Proxima_Nova_Light) format('svg');
    font-style: normal;
    font-weight: 300
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/fonts/Proxima_Nova_Light_Italic.eot);
    src: url(/static/fonts/Proxima_Nova_Light_Italic.eot) format('embedded-opentype'), url(/static/fonts/Proxima_Nova_Light_Italic.woff2) format('woff2'), url(/static/fonts/Proxima_Nova_Light_Italic.woff) format('woff'), url(/static/fonts/Proxima_Nova_Light_Italic.ttf) format('truetype'), url(/static/fonts/Proxima_Nova_Light_Italic.svg#Proxima_Nova_Light_Italic) format('svg');
    font-style: italic;
    font-weight: 300
}
body, html, input, select, textarea{
  font-family: 'Proxima Nova', sans-serif !important;
  font-size: 12px !important;
  color: #343434;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-family: 'Proxima Nova', sans-serif !important;
}

.enrollment_header .text-muted{
  font-size: 12px !important;
  color: #868e96 !important;
}
.load_primary_information dd{
  color: #868e96;
  margin-bottom: 0;
}
.load_primary_information dt{
  color: black;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}
.load_primary_information dt:last-child{
  margin-bottom: 4px;
}
.load_primary_information .content_title {
  font-weight: 400;
  font-size: 13px;
  margin: 0px 0 10px;
  color: #2e1153;
}
.load_primary_information .content_title .fa, .load_primary_information .content_title .font-icon {
  font-size: 12px;
  margin-right: 5px;
}
.load_primary_information .home_address_inner {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
}
.load_primary_information .home_address_inner .home_address_inner_left {
    width: 35px;
    align-self: center;
}
.load_primary_information .home_address_inner .home_address_inner_right {
    width: calc(100% - 35px);
}
.load_primary_information .home_address_inner .home_address_inner_left .fa-map-marker {
    color: #2e1153;
    background: #f1f0fb;
    border-radius: 100%;
    font-size: 14px;
    padding: 6px 9px;
    border: 1px solid #ceccda;
}
.load_primary_information .home_address_inner:last-child{
  margin-bottom: 0;
}
/* .left_side_content {
  min-height: 300px;
} */
.left_side_content .content_title .fa, .left_side_content .content_title .font-icon {
    font-size: 12px;
    margin-right: 5px;
}
.only_contact_detail_div dt{
    font-weight: 500;
    font-size: 13px;
  }
.left_side_content .content_title {
    font-weight: 400;
    font-size: 13px;
    margin: 0px 0 10px;
    color: #2e1153;
}
.left_side_content .only_contact_detail_div dt, .left_side_content .only_emg_contact_div dt {
    margin-bottom: 4px;
}
.left_side_content .single_glance_row {
    background-color: rgb(255 255 255);
    font-size: 12px !important;
    margin: 0 !important;
    border: none;
    border-bottom: 1px solid #e8eef1;
    border-radius: 0;
    padding: 8px 10px;
    color: #343434;
    font-size: 1rem;
}
</style>
